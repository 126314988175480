import Vue from 'vue';
import Vuex from 'vuex';

import usersStore from '@/store/modules/users';
import helpTopicsStore from '@/store/modules/helptopics';
import designsStore from '@/store/modules/designs';
import ordersStore from '@/store/modules/orders';
import customersStore from '@/store/modules/customers';
import messagesStore from '@/store/modules/messages';
import paymentMethodsStore from '@/store/modules/paymentmethods';
import paymentProfilesStore from '@/store/modules/paymentprofiles';
import countriesStore from '@/store/modules/countries';
import statesStore from '@/store/modules/states';
import navigationStore from '@/store/modules/navigation';
import activityStore from '@/store/modules/activities';
import carrierAccountsStore from '@/store/modules/carrieraccounts';
import addressesStore from '@/store/modules/addresses';
import invoicesStore from '@/store/modules/invoices';
import paymentsStore from '@/store/modules/payments';
import servicesStore from '@/store/modules/services';
import designLocationsStore from '@/store/modules/designlocations';
import alertsStore from '@/store/modules/alerts';
import paymentTermsStore from '@/store/modules/paymentterms';
import approvalsStore from '@/store/modules/approvals';
import productsStore from '@/store/modules/products';
import priceListsStore from '@/store/modules/pricelists';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    usersStore,
    helpTopicsStore,
    designsStore,
    ordersStore,
    customersStore,
    messagesStore,
    paymentMethodsStore,
    paymentProfilesStore,
    countriesStore,
    statesStore,
    navigationStore,
    activityStore,
    carrierAccountsStore,
    addressesStore,
    invoicesStore,
    paymentsStore,
    servicesStore,
    designLocationsStore,
    alertsStore,
    paymentTermsStore,
    approvalsStore,
    productsStore,
    priceListsStore,
  },
  strict: debug,
});
