<template>
  <div>
    <v-dialog v-model="forgotPasswordDialog" persistent max-width="420">
      <v-card>
        <v-form ref="forgetPassword" v-model="valid" lazy-validation>
          <v-card-title
            class="secondary white--text headline font-weight-medium"
          >
            FORGOT PASSWORD
            <v-spacer />
            <v-btn
              flat
              icon
              color="white"
              @click="forgotPasswordDialog = false"
            >
              <v-icon>fal fa-times</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            Enter your email address. If the email address is registered with
            the system, instructions to reset the password for the account will
            be sent to that email address.
            <v-text-field
              v-model="emailAddress"
              prepend-icon="fal fa-envelope"
              label="Email Address"
              placeholder=" "
              persistent-placeholder
              type="text"
              required
              :rules="rules.emailAddress"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              rounded
              block
              @click="submitForgotPassword()"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm6 md4 lg4 xl3>
          <v-card class="rounded-card pt-1">
            <v-layout ma-3>
              <v-img contain :src="require('@/assets/img/logo_login.png')" />
            </v-layout>
            <v-spacer />
            <v-card-text>
              <v-form v-if="admin !== true" @submit.prevent="onLogin">
                <v-text-field
                  v-model="email"
                  prepend-icon="fal fa-user-circle"
                  label="Login"
                  type="text"
                />
                <v-text-field
                  id="password"
                  v-model="password"
                  prepend-icon="fal fa-lock-alt"
                  label="Password"
                  type="password"
                />
                <v-btn
                  v-if="admin !== true"
                  rounded
                  color="primary"
                  block
                  type="submit"
                  depressed
                >
                  Login
                </v-btn>
              </v-form>
              <v-autocomplete
                v-if="admin === true"
                v-model="customerID"
                :items="customersStore.customers"
                item-text="d_companyName"
                item-value="id"
                label="Select Customer"
                placeholder=" "
                persistent-placeholder
                clearable
                clear-icon="fal fa-times-circle"
              />
              <v-btn
                v-if="admin === true"
                rounded
                color="primary"
                block
                type="submit"
                depressed
                @click="adminLogin()"
              >
                Admin Login
              </v-btn>
              <v-container v-if="admin !== true">
                <v-layout>
                  <v-flex xs12 class="pt-6 text-md-center">
                    <v-btn
                      text
                      small
                      color="primary"
                      @click="openForgotPasswordDialog()"
                    >
                      Forgot Your Password?
                    </v-btn>
                  </v-flex>
                </v-layout>

                <v-layout>
                  <v-flex xs12 class="pt-6 text-md-center">
                    <span class="secondary--text"
                      >Please contact
                      <a href="mailto:portal@idexint.com" color="primary"
                        >portal@idexint.com</a
                      >
                      to register for an account</span
                    >
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { clientID, clientSecret, getHeader } from "@/env";

export default {
  name: "Login",
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      alertsStore: state => state.alertsStore,
      customersStore: state => state.customersStore
    })
  },
  props: {
    source: String
  },
  data() {
    return {
      email: "",
      password: "",
      snackbarMessage: false,
      snackbarColor: "",
      snackbarText: "",
      customerID: "",
      forgotPasswordDialog: false,
      emailAddress: "",
      admin: false,
      adminUser: {},
      valid: true,
      rules: {
        emailAddress: [v => !!v || "required"]
      }
    };
  },
  methods: {
    /* ---------- Login ----------*/
    async onLogin() {
      /* Build initial data for token request */
      const data = {
        client_id: clientID,
        client_secret: clientSecret,
        grant_type: "password",
        username: this.email,
        password: this.password
      };
      const authUser = {};
      try {
        const response = await axios.post("/oauth/token", data);
        /* Grab response tokens and put the info in local storage */
        authUser.access_token = response.data.access_token;
        authUser.refresh_token = response.data.refresh_token;
        authUser.expires_in = response.data.expires_in;
        const headers = {
          Accept: "application/json",
          Authorization: `Bearer ${authUser.access_token}`
        };

        try {
          const response2 = await axios.get("/api/user", { headers });
          if (response2.data.f_access === 1) {
            /* push auth tokens into user json */
            response2.data.access_token = authUser.access_token;
            response2.data.refresh_token = authUser.refresh_token;
            response2.data.expires_in = authUser.expires_in;

            /* Store auth user */
            window.localStorage.setItem(
              "authUser",
              JSON.stringify(response2.data)
            );

            if (response2.data.f_admin === 1) {
              this.adminUser = response2.data;
              this.admin = true;
              await this.$store.dispatch("customersStore/fetchAll");
            } else {
              /* Store authorized user in store */
              await this.$store.dispatch(
                "usersStore/setAuthUser",
                response2.data
              );
              /* Redirect user to dashboard */
              this.$router.push({ name: "dashboard" });
            }
          } else {
            /* Show login error */
            this.openSnackbar(1);
          }
        } catch (error) {
          /* Show login error */
          this.openSnackbar(2);
        }
      } catch (error) {
        /* Show login error */
        this.openSnackbar(3);
      }
    },

    openForgotPasswordDialog() {
      this.forgotPasswordDialog = true;
    },

    openSnackbar(code) {
      let data;
      if (code === 1) {
        data = {
          color: "error",
          text: "Login error - access has expired. Please contact support."
        };
      } else if (code === 2) {
        data = {
          color: "error",
          text: "Login error - Please contact support."
        };
      } else if (code === 3) {
        data = {
          color: "error",
          text: "Login error - email and password combination are incorrect."
        };
      } else if (code === 4) {
        data = {
          color: "green lighten-1",
          text: "Password reset email sent successfully."
        };
      } else if (code === 5) {
        data = {
          color: "error",
          text:
            "There was an error with the request. Please contact support if the issue continues."
        };
      } else if (code === 6) {
        data = {
          color: "error",
          text: "Select customer before continuing."
        };
      }
      this.$store.commit("alertsStore/setAlert", data);
    },

    async submitForgotPassword() {
      if (this.$refs.forgetPassword.validate()) {
        const data = {
          email: this.emailAddress
        };
        try {
          await axios.post("/api/password/email", data);
          this.forgotPasswordDialog = false;
          this.emailAddress = "";
          this.openSnackbar(4);
        } catch (error) {
          // console.log(error)
          this.forgotPasswordDialog = false;
          this.emailAddress = "";
          this.openSnackbar(5);
        }
      }
    },

    adminLogin() {
      if (!this.customerID) {
        this.openSnackbar(6);
      } else {
        this.adminUser.fk_customerID = this.customerID;
        /* Store auth user */
        window.localStorage.setItem("authUser", JSON.stringify(this.adminUser));
        /* Store authorized user in store */
        this.$store.dispatch("usersStore/setAuthUser", this.adminUser);

        this.$router.push({ name: "dashboard" });
      }
    }
  }
};
</script>

<style scoped>
.rounded-card {
  border-radius: 8px;
}
button {
  outline: none;
}
</style>
