import { getHeader } from '@/env';

const state = {
  paymentTerms: [],
  paymentTerm: {},
};

const mutations = {
  CLEAR_PAYMENT_TERM_STORE(state) {
    state.paymentTerms = [];
    state.paymentTerm = {};
  },
  FETCH(state, { list }) {
    state.paymentTerms = list;
  },
  CREATE(state, { data }) {
    state.paymentTerms.push(data);
  },
  READ(state, { data }) {
    state.paymentTerm = data;
  },
  UPDATE(state, { data }) {
    const idx = state.paymentTerms.map((item) => item.id).indexOf(data.id);
    state.paymentTerms.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.paymentTerms.map((item) => item.id).indexOf(id);
    state.paymentTerms.splice(idx, 1);
  },
};

const actions = {
  clearPaymentTermStore: ({ commit }) => {
    commit('CLEAR_PAYMENT_TERM_STORE');
  },
  async fetch({ commit }) {
    try {
      const response = await axios.get('/api/paymentterms', { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/paymentterms/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/paymentterms/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/paymentterms/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/paymentterms/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async query({ commit }, data) {
    try {
      const response = await axios.post('/api/paymentterms/query', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
};

const getters = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
