import { getHeader } from '@/env';

const state = {
  invoices: [],
  openInvoices: [],
  invoice: {},
  filters: {
    id: '',
    customerID: '',
    orderId: '',
    date: '',
    po: '',
    jobName: '',
    status: '',
    page: '',
  },
};

const mutations = {
  CLEAR_INVOICE_STORE(state) {
    state.invoices = [];
    state.openInvoices = [];
    state.invoice = {};
    state.filters = {};
  },
  FETCH(state, { list }) {
    state.invoices = list;
  },
  FETCH_OPEN(state, { list }) {
    state.openInvoices = list;
  },
  CREATE(state, { data }) {
    state.invoices.push(data);
  },
  READ(state, { data }) {
    state.invoice = data;
  },
  UPDATE(state, { data }) {
    const idx = state.invoices.data.map((item) => item.id).indexOf(data.id);
    state.invoices.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.invoices.data.map((item) => item.id).indexOf(id);
    state.invoices.data.splice(idx, 1);
  },
  STORE_FILTERS(state, { data }) {
    state.filters = data;
  },
};

const actions = {
  clearInvoiceStore: ({ commit }) => {
    commit('CLEAR_INVOICE_STORE');
  },
  async fetch({ commit }) {
    try {
      const response = await axios.get('/api/invoices', { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async fetchByCustomer({ commit }, data) {
    try {
      const response = await axios.post('/api/invoices/customer', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async fetchByCustomerOpen({ commit }, data) {
    try {
      const response = await axios.post('/api/invoices/customer/open', data, { headers: getHeader() });
      commit('FETCH_OPEN', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/invoices/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/invoices/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async readUUID({ commit }, uuid) {
    try {
      const response = await axios.get(`/api/invoices/detail/${uuid}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/invoices/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/invoices/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async query({ commit }, data) {
    try {
      const response = await axios.post('/api/invoices/query', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  storeFilters: ({ commit }, data) => {
    commit('STORE_FILTERS', { data });
  },
};

const getters = {
  getInvoice: (state, getters) => state.invoice,
  getOpenInvoices: (state, getters) => state.openInvoices,
  getFilters: (state, getters) => state.filters,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
