import { getHeader } from '@/env';

const state = {
  navigation: [],
  nav: {},
};

const mutations = {
  FETCH(state, { list }) {
    state.navigation = list;
  },
  CREATE(state, { data }) {
    state.navigation.push(data);
  },
  READ(state, { data }) {
    state.nav = data;
  },
  UPDATE(state, { data }) {
    const idx = state.navigation.map((item) => item.id).indexOf(data.id);
    state.navigation.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.navigation.map((item) => item.id).indexOf(id);
    state.navigation.splice(idx, 1);
  },
};

const actions = {
  async fetch({ commit }) {
    try {
      const response = await axios.get('/api/navigation', { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/navigation/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/navigation/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/navigation/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/navigation/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async query({ commit }, data) {
    try {
      const response = await axios.post('/api/navigation/query', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
};

const getters = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
