import { getHeader } from '@/env';

const state = {
  users: [],
  authUser: null,
  appMode: 1,
  user: {},
};

const mutations = {
  CLEAR_USER_STORE(state) {
    state.users = [];
    state.authUser = null;
    state.appMode = 1;
    state.user = {};
  },
  FETCH(state, { list }) {
    state.users = list;
  },
  CREATE(state, { data }) {
    state.users.push(data);
  },
  READ(state, { data }) {
    state.user = data;
  },
  UPDATE(state, { data }) {
    const idx = state.users.map((item) => item.id).indexOf(data.id);
    state.users.splice(idx, 1, data);
    if (data.id === state.authUser.id) {
      state.authUser = data;
      state.user = data;
    }
  },
  DELETE(state, { id }) {
    const idx = state.users.map((item) => item.id).indexOf(id);
    state.users.splice(idx, 1);
  },
  SET_AUTH_USER(state, userObj) {
    state.authUser = userObj;
  },
  SET_APP_MODE(state, mode) {
    state.appMode = mode;
  },
  UPDATE_AUTH_USER(state, data) {
    state.authUser = data;
  },
};

const actions = {
  clearUserStore: ({ commit }) => {
    commit('CLEAR_USER_STORE');
  },
  async fetch({ commit }) {
    try {
      const response = await axios.get('/api/users', { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async fetchByCustomer({ commit }, data) {
    try {
      const response = await axios.post('/api/users/customer', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/users/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/users/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/users/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/users/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async query({ commit }, data) {
    try {
      const response = await axios.post('/api/users/query', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  setAuthUser: ({ commit }, userObj) => {
    commit('SET_AUTH_USER', userObj);
  },
  setAppMode: ({ commit }, mode) => {
    commit('SET_APP_MODE', mode);
  },

};

const getters = {
  getAuthUser: (state, getters) => state.authUser,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
