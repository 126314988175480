<template>
  <v-app class="accent">
    <!-- Alert -->
    <alert></alert>

    <!--Change Password Dialog-->
    <v-dialog v-model="passwordDialog" persistent max-width="400">
      <v-card>
        <v-form ref="updatePassword" v-model="valid" lazy-validation>
          <v-card-title
            class="secondary white--text headline font-weight-medium"
          >
            Change Password
            <v-spacer></v-spacer>
            <v-btn text icon color="white" @click="passwordDialog = false">
              <v-icon>fal fa-times</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout>
                <v-flex xs12>
                  <v-text-field
                    prepend-icon="fas fa-lock"
                    v-model="password"
                    label="Password"
                    placeholder=" "
                    persistent-placeholder
                    type="password"
                    required
                    :rules="rules.password"
                  >
                  </v-text-field>
                  <v-text-field
                    prepend-icon="fas fa-lock"
                    v-model="passwordConfirmation"
                    label="Confirm Password"
                    placeholder=" "
                    persistent-placeholder
                    type="password"
                    required
                    :rules="rules.passwordConfirm"
                  >
                  </v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn rounded block color="primary" @click="updatePassword()">
              <v-icon small left>fal fal fa-check</v-icon>
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!--Logged In Navigation Drawer-->
    <v-navigation-drawer
      v-if="usersStore.authUser"
      v-model="drawer"
      fixed
      app
      :floating="true"
      width="240"
      class="accent"
    >
      <!--Logo-->
      <v-card flat class="pt-2" color="accent">
        <v-img
          contain
          height="60px"
          :src="require('@/assets/img/logo_small2.png')"
        >
        </v-img>
      </v-card>

      <v-list rounded class="pt-4">
        <v-list-item
          v-for="item in usersStore.authUser.navigation"
          v-if="
            (item.f_section === 1 && usersStore.appMode !== 2) ||
              (item.f_section === 2 && usersStore.appMode === 2)
          "
          :key="item.id"
          :to="{ name: item.d_url }"
          active-class="secondary--text font-weight-bold"
        >
          <v-list-item-action>
            <v-icon>
              {{ item.d_icon }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.d_title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- Messages Edit Dialog -->
    <v-dialog v-model="messageDialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="primary">
          <span class="headline white--text">Message</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md class="pa-0">
            <v-layout wrap>
              <v-flex xs12>
                <div class="subtitle-1">{{ message.d_subject }}</div>
                <p>{{ message.d_details }}</p>
              </v-flex>
              <v-flex xs12>
                <v-switch
                  label="Open"
                  color="green lighten-1"
                  v-model="message.f_status"
                ></v-switch>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" flat @click="messageDialog = false">Cancel</v-btn>
          <v-btn color="secondary" @click="saveMessage()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Top Toolbar-->
    <v-app-bar color="accent" fixed app flat>
      <v-app-bar-nav-icon
        v-if="usersStore.authUser"
        @click.stop="drawer = !drawer"
        class="hidden-lg-and-up"
        color="grey--text text--darken-2"
      >
      </v-app-bar-nav-icon>

      <!--Customer Name Title-->
      <v-toolbar-title v-if="usersStore.authUser" class="ml-0 pl-0">
        <span class="headline grey--text text--darken-2 hidden-sm-and-down">
          {{ customersStore.customer.d_companyName }}</span
        >
      </v-toolbar-title>

      <!--Static Nav Buttons-->
      <v-toolbar-items
        class="hidden-sm-and-down"
        v-if="!usersStore.authUser && staticNav"
      >
        <v-btn
          v-for="(item, i) in navItems"
          :key="i"
          :to="{ name: item.url }"
          flat
          exact
        >
          <v-icon>
            {{ item.icon }}
          </v-icon>
          {{ item.title }}
        </v-btn>
      </v-toolbar-items>

      <v-spacer></v-spacer>

      <!--User Name When Logged In-->
      <v-flex class="grey--text text--darken-2 subtitle-1" text-md-right>
        <div v-if="usersStore.authUser">{{ usersStore.authUser.name }}</div>
      </v-flex>

      <!--User Profile Button-->
      <v-menu v-if="usersStore.authUser" open-on-hover offset-y left>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon large>
            <v-avatar size="32px" tile>
              <v-icon :color="usersStore.authUser.d_avatarColor">
                fas fa-user-circle
              </v-icon>
            </v-avatar>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click.native="openChangePassword()">
            <v-list-item-title class="clickable"
              >Change Password</v-list-item-title
            >
          </v-list-item>
          <v-list-item @click.native="handleLogout">
            <v-list-item-title class="clickable">Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <!--Notification Button-->
      <v-btn
        v-if="usersStore.authUser && usersStore.appMode !== 2 && 1 === 2"
        icon
        @click="switchAppModes(2)"
      >
        <v-icon color="grey darken-2">
          fal fa-bell
        </v-icon>
      </v-btn>

      <!--Settings Button-->
      <v-btn
        v-if="
          usersStore.authUser &&
            usersStore.authUser.f_admin === 1 &&
            usersStore.appMode !== 2
        "
        icon
        @click="switchAppModes(2)"
      >
        <v-icon color="grey darken-2">
          fal fa-cog
        </v-icon>
      </v-btn>

      <!--Home Button-->
      <v-btn
        v-if="
          usersStore.authUser &&
            usersStore.authUser.f_admin === 1 &&
            usersStore.appMode === 2
        "
        icon
        @click.native="switchAppModes()"
      >
        <v-icon color="grey darken-2">
          fal fal fa-home
        </v-icon>
      </v-btn>

      <!--Sign Up Button-->
      <v-btn
        v-if="
          !usersStore.authUser &&
            this.$route.name !== 'signUp' &&
            this.$route.name !== 'register' &&
            this.$route.name !== 'documentation'
        "
        color="white"
        class="primary--text"
        @click.native="goSignUp()"
      >
        New Customer Sign Up
        <v-icon right>fas fa-user-circle</v-icon>
      </v-btn>

      <!--Login Button-->
      <v-btn
        v-if="
          (!usersStore.authUser && showMiniLogin) ||
            this.$route.name === 'signUp' ||
            this.$route.name === 'register'
        "
        color="white"
        class="primary--text"
        @click.native="goLogin()"
      >
        Log In
        <v-icon right>fas fa-user-circle</v-icon>
      </v-btn>

      <!-- Help Dialog Box -->
      <v-dialog v-model="helpDialog" max-width="800px">
        <v-card>
          <v-card-title>
            <span class="headline">Help - {{ help.d_title }}</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  {{ help.d_text }}
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="" flat @click.native="closeHelp()">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app-bar>

    <!--Content-->
    <v-main>
      <v-container fluid fill-height class="accent">
        <v-layout justify-center child-flex>
          <router-view></router-view>
        </v-layout>
      </v-container>
    </v-main>

    <!--Footer-->
    <v-footer app class="pa-3" color="grey darken-2">
      <v-flex text-xs-left class="grey--text text--lighten-1">
        {{ applicationName }} - &copy; {{ new Date().getFullYear() }} v{{
          applicationVersion
        }}

        <!--Online PDF-->
        <v-btn
          class="pl-4"
          small
          icon
          href="https://idexportal.s3.amazonaws.com/iDex+Web+Portal+Overview.pdf"
          target="_blank"
        >
          <v-icon color="grey lighten-1">
            fas fa-question-circle
          </v-icon>
        </v-btn>

        <!--Documentation-->
        <v-btn
          class="pl-4"
          v-if="usersStore.authUser && this.$route.name !== 'documentation'"
          small
          icon
        >
          <v-icon color="grey lighten-1" @click.native="launchDocumentation">
            fal fa-book
          </v-icon>
        </v-btn>
      </v-flex>

      <!--Help-->
      <v-btn v-if="usersStore.authUser && 1 === 2" small icon class="ma-0">
        <v-icon color="grey lighten-1" @click.native="launchHelp">
          fas fa-question-circle
        </v-icon>
      </v-btn>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { appName, siteVersion, getHeader } from "@/env";
import Alert from "@/components/Alert";

export default {
  computed: mapState({
    usersStore: state => state.usersStore,
    customersStore: state => state.customersStore,
    helpTopicsStore: state => state.helpTopicsStore,
    messagesStore: state => state.messagesStore
  }),
  components: {
    Alert
  },
  data() {
    return {
      applicationName: appName,
      applicationVersion: siteVersion,
      helpDialog: false,
      messageDialog: false,
      help: [],
      drawer: true,
      staticNav: false,
      passwordDialog: false,
      password: "",
      passwordConfirmation: "",
      valid: "",
      showMiniLogin: false,
      snackbarShow: false,
      snackbarColor: "",
      snackbarText: "",
      message: {
        id: "",
        d_subject: "",
        d_details: "",
        f_status: ""
      },
      rules: {
        password: [v => !!v || "required"],
        passwordConfirm: [
          v => !!v || "required",
          v => v === this.password || "Passwords must match"
        ]
      },
      navItems: [
        {
          url: "index",
          icon: "",
          title: "Home",
          subItems: [
            {
              url: "",
              title: ""
            }
          ]
        },
        {
          url: "about",
          icon: "",
          title: "About Us",
          subItems: [
            {
              url: "",
              title: ""
            }
          ]
        },
        {
          url: "services",
          icon: "",
          title: "Services",
          subItems: [
            {
              url: "",
              title: ""
            }
          ]
        },
        {
          url: "resources",
          icon: "",
          title: "Resources",
          subItems: [
            {
              url: "",
              title: "Test"
            }
          ]
        }
      ]
    };
  },
  props: {
    source: String
  },
  created() {
    const userObj = JSON.parse(window.localStorage.getItem("authUser"));
    if (userObj) {
      this.$store.dispatch("usersStore/setAuthUser", userObj).then(() => {
        /* Grab app mode from local storage */
        const appMode = JSON.parse(window.localStorage.getItem("appMode"));
        if (appMode == null) {
          const appMode = 1;
          window.localStorage.removeItem("appMode");
        }

        const { orderUUID } = this.$route.params;

        if (orderUUID) {
          this.$router.push({ name: "orderDetail", params: { orderUUID } });
        } else {
          this.switchAppModes(appMode);
        }
      });
    } else if (
      this.$route.name === "register" ||
      this.$route.name === "passwordreset" ||
      this.$route.name === "signUp"
    ) {
    } else {
      this.handleLogout();
    }
  },
  methods: {
    switchAppModes(mode) {
      /* Store authorized user in store */
      this.$store.dispatch("usersStore/setAppMode", mode).then(() => {
        /* Grab app mode from local storage */
        if (mode === 2) {
          window.localStorage.setItem("appMode", 2);
          this.$router.push({ name: "customers" });
        } else {
          window.localStorage.removeItem("appMode");
          this.$router.push({ name: "dashboard" });
        }
      });
    },
    launchHelp() {
      axios
        .get(`/api/helptopics/help/${this.$route.name}`, {
          headers: getHeader()
        })
        .then(response => {
          this.help.d_title = response.data.d_title;
          this.help.d_text = response.data.d_text;
          this.helpDialog = true;
        })
        .catch(error => {});
    },
    goLogin() {
      const authUser = this.$store.getters["usersStore/getAuthUser"];
      if (authUser) {
        this.$router.push({ name: "dashboard" });
      } else {
        this.$router.push({ name: "login" });
      }
    },
    goSignUp() {
      this.$router.push({ name: "signUp" });
    },
    handleLogout() {
      this.$store.dispatch("usersStore/clearUserStore");

      window.localStorage.removeItem("authUser");

      /* Send user to index */
      this.$router.push({ name: "login" });
    },
    closeHelp() {
      this.helpDialog = false;
    },

    openChangePassword() {
      this.valid = true;
      this.password = "";
      this.passwordConfirmation = "";
      this.passwordDialog = true;
    },

    /* ----------Reset Password---------*/
    updatePassword() {
      if (this.$refs.updatePassword.validate()) {
        const authUser = this.$store.getters["usersStore/getAuthUser"];

        /* Grab data from update password form */
        const data = {
          id: authUser.id,
          password: this.password
        };
        /* POST user data to Auth API */
        axios
          .post("/api/user/password", data, { headers: getHeader() })
          .then(response => {
            /* Show success notification */
            this.openSnackbar(1);
          })
          .catch(error => {
            /* Show error notification */
            this.openSnackbar(2);
          });
        this.passwordDialog = false;
      }
    },

    openSnackbar(code) {
      if (code === 1) {
        this.snackbarColor = "green lighten-1";
        this.snackbarText = "Password has been updated.";
      } else if (code === 2) {
        this.snackbarColor = "error";
        this.snackbarText = "Error updating password.";
      }
      this.snackbarShow = true;
    },

    launchDocumentation() {
      this.$router.push({ name: "documentation" });
    }
  }
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
