<template>
  <div>
    <!--Progress Bar-->
    <v-dialog v-model="progress" max-width="290" persistent hide-overlay>
      <v-card color="primary" dark>
        <v-card-text class="text-center">
          Loading
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Delete Dialog Box -->
    <v-dialog v-model="cancelDialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="secondary white--text headline font-weight-medium">
          Cancel Order?
          <v-spacer />
          <v-btn text icon color="white" @click="cancelDialog = false">
            <v-icon>fal fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                This order will be canceled!
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="error" @click.native="cancelOrder()">
            <v-icon small left> fal fa-ban </v-icon>Cancel Order
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container v-if="!progress" fluid fill-height grid-list-lg>
      <v-layout row wrap>
        <v-flex xs12>
          <v-toolbar flat dense color="secondary" dark>
            <v-icon>fal fa-shopping-cart</v-icon>
            <v-toolbar-title class="pl-2">
              Order Details
            </v-toolbar-title>
            <v-spacer />
            <v-btn
              v-if="order.f_status === 999"
              class="mx-4"
              color="red lighten-1"
              rounded
              @click="openCancelDialog()"
            >
              <v-icon small left> fal fa-ban </v-icon>Cancel Order
            </v-btn>
            <v-btn
              v-if="
                order.f_status === 2 ||
                  order.f_status === 3 ||
                  order.f_status === 4 ||
                  order.f_status === 999
              "
              outlined
              rounded
              @click="editOrder(order.uuid, 1, order.f_status)"
            >
              <v-icon small left> fal fa-pencil </v-icon>Edit
            </v-btn>
          </v-toolbar>

          <v-card flat color="accent">
            <v-card-text class="px-0">
              <v-container fluid grid-list-md class="pt-0 px-0">
                <v-layout row wrap>
                  <!--Left Side-->
                  <v-flex sx12 md4>
                    <v-card flat height="100%">
                      <v-card-text>
                        <!--Company Name-->
                        <v-layout
                          v-if="usersStore.authUser.customers.length > 0"
                          row
                          wrap
                        >
                          <v-flex xs12 class="py-0">
                            <div
                              class="subtitle-1 grey--text text--darken-3 font-weight-bold"
                            >
                              {{ order.customer.d_companyName }}
                            </div>
                          </v-flex>
                        </v-layout>

                        <!--Order Number & Due Date-->
                        <v-layout row wrap>
                          <v-flex xs12 md6>
                            <v-layout row wrap>
                              <v-flex xs12 class="py-0">
                                <div class="caption">
                                  Order Number
                                </div>
                              </v-flex>
                              <v-flex xs12 class="py-0">
                                <div
                                  class="subtitle-1 grey--text text--darken-3 font-weight-bold"
                                >
                                  {{ order.d_orderNumber }}
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 md6>
                            <v-layout row wrap>
                              <v-flex xs12 class="py-0">
                                <div class="caption">
                                  Order Date
                                </div>
                              </v-flex>
                              <v-flex xs12 class="py-0">
                                <div
                                  class="subtitle-1 grey--text text--darken-3 font-weight-bold"
                                >
                                  {{ formatDate(order.d_dateOrder) }}
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>

                        <!--Order Type-->
                        <v-layout row wrap class="py-4">
                          <v-flex xs12>
                            <v-layout row wrap>
                              <v-flex xs12 class="py-0">
                                <div class="caption">
                                  Type
                                </div>
                              </v-flex>
                              <v-flex xs12 class="py-0">
                                <div
                                  class="subtitle-1 grey--text text--darken-3 font-weight-bold"
                                >
                                  {{ translateType(order.f_type) }}
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>

                        <!--Due Date & Priority-->
                        <v-layout row wrap class="py-4">
                          <v-flex xs12 md6>
                            <v-layout row wrap>
                              <v-flex xs12 class="py-0">
                                <div class="caption">
                                  Due Date
                                </div>
                              </v-flex>
                              <v-flex xs12 class="py-0">
                                <div
                                  class="subtitle-1 grey--text text--darken-3 font-weight-bold"
                                >
                                  {{ order.d_dateDue }}
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 md6>
                            <v-layout row wrap>
                              <v-flex xs12 class="py-0">
                                <div class="caption">
                                  Rush
                                </div>
                              </v-flex>
                              <v-flex xs12 class="py-0">
                                <div
                                  class="subtitle-1 grey--text text--darken-3 font-weight-bold"
                                >
                                  {{ translatePriority(order.f_priority) }}
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>

                        <!--Contact-->
                        <v-layout row wrap class="py-4">
                          <v-flex xs12>
                            <v-layout row wrap>
                              <v-flex xs12 class="py-0">
                                <div class="caption">
                                  Contact
                                </div>
                              </v-flex>
                              <v-flex xs12 class="py-0">
                                <div
                                  v-if="order.user"
                                  class="subtitle-1 grey--text text--darken-3 font-weight-bold"
                                >
                                  {{ order.user.name }}
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                    </v-card>
                  </v-flex>

                  <!--Middle-->
                  <v-flex xs12 md4>
                    <v-card flat height="100%">
                      <v-card-text>
                        <!--Job Name & PO-->
                        <v-layout row wrap>
                          <v-flex xs12 md6>
                            <v-layout row wrap>
                              <v-flex xs12 class="py-0">
                                <div class="caption">
                                  Job Name
                                </div>
                              </v-flex>
                              <v-flex xs12 class="py-0">
                                <div
                                  class="subtitle-1 grey--text text--darken-3 font-weight-bold"
                                >
                                  {{ order.d_jobName }}
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 md6>
                            <v-layout row wrap>
                              <v-flex xs12 class="py-0">
                                <div class="caption">
                                  Customer PO
                                </div>
                              </v-flex>
                              <v-flex xs12 class="py-0">
                                <div
                                  class="subtitle-1 grey--text text--darken-3 font-weight-bold"
                                >
                                  {{ order.d_customerPurchaseOrder }}
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>

                        <!--Ship From & Ship To-->
                        <v-layout row wrap class="py-4">
                          <!--Left Side-->
                          <v-flex xs12 md6 class="py-0">
                            <v-flex xs12 class="py-0 pl-0">
                              <div class="caption">
                                Ship From
                              </div>
                            </v-flex>
                            <v-flex
                              v-if="order.address_from"
                              xs12
                              class="subtitle-1 py-0 pl-0 black--text"
                            >
                              <div
                                v-if="order.address_from.d_company"
                                class="subtitle-1 grey--text text--darken-3 font-weight-bold"
                              >
                                {{ order.address_from.d_company }}<br />
                              </div>
                              <div v-if="order.address_from.d_street1">
                                {{ order.address_from.d_street1 }}<br />
                              </div>
                              <div v-if="order.address_from.d_street2">
                                {{ order.address_from.d_street2 }}<br />
                              </div>
                              <div>
                                <span v-if="order.address_from.d_city"
                                  >{{ order.address_from.d_city }},
                                </span>
                                <span v-if="order.address_from.d_state"
                                  >{{ order.address_from.d_state }}
                                </span>
                                <span v-if="order.address_from.d_zip">{{
                                  order.address_from.d_zip
                                }}</span>
                              </div>
                            </v-flex>
                          </v-flex>

                          <!--Right Side-->
                          <v-flex xs12 md6 class="py-0">
                            <v-flex xs12 class="py-0 pl-0">
                              <div class="caption">
                                Ship To
                              </div>
                            </v-flex>
                            <v-flex
                              v-if="order.address_to"
                              xs12
                              class="subtitle-1 py-0 pl-0 black--text"
                            >
                              <div
                                v-if="order.address_to.d_company"
                                class="subtitle-1 grey--text text--darken-3 font-weight-bold"
                              >
                                {{ order.address_to.d_company }}<br />
                              </div>
                              <div v-if="order.address_to.d_attention">
                                Attn: {{ order.address_to.d_attention }}<br />
                              </div>
                              <div v-if="order.address_to.d_street1">
                                {{ order.address_to.d_street1 }}
                                <span v-if="order.address_to.d_street2">
                                  , {{ order.address_to.d_street2 }} </span
                                ><br />
                              </div>
                              <div>
                                <span v-if="order.address_to.d_city"
                                  >{{ order.address_to.d_city }},
                                </span>
                                <span v-if="order.address_to.d_state"
                                  >{{ order.address_to.d_state }}
                                </span>
                                <span v-if="order.address_to.d_zip">{{
                                  order.address_to.d_zip
                                }}</span>
                              </div>
                            </v-flex>
                          </v-flex>
                        </v-layout>

                        <!--Carrier & Method-->
                        <v-layout
                          v-if="order.f_pickUp === 1"
                          row
                          wrap
                          class="py-4"
                        >
                          <v-flex v-if="order.shipping_carrier" xs12 md4>
                            <v-layout row wrap>
                              <v-flex xs12 class="py-0">
                                <div class="caption">
                                  Carrier
                                </div>
                              </v-flex>
                              <v-flex xs12 class="py-0">
                                <div
                                  class="subtitle-1 grey--text text--darken-3 font-weight-bold"
                                >
                                  {{ order.shipping_carrier.d_name }}
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex v-if="order.fk_shippingMethodID" xs12 md8>
                            <v-layout row wrap>
                              <v-flex xs12 class="py-0">
                                <div class="caption">
                                  Method
                                </div>
                              </v-flex>
                              <v-flex xs12 class="py-0">
                                <div
                                  class="subtitle-1 grey--text text--darken-3 font-weight-bold"
                                >
                                  {{
                                    translateCarrierMethod(
                                      order.fk_shippingMethodID
                                    )
                                  }}
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                        <v-layout v-else row wrap class="py-4">
                          <v-flex v-if="order.shipping_carrier" xs12 md4>
                            <v-layout row wrap>
                              <v-flex xs12 class="py-0">
                                <div class="caption">
                                  Pick Up
                                </div>
                              </v-flex>
                              <v-flex xs12 class="py-0">
                                <div
                                  class="subtitle-1 grey--text text--darken-3 font-weight-bold"
                                >
                                  Yes
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                    </v-card>
                  </v-flex>

                  <!--Right Side-->
                  <v-flex xs12 md4>
                    <v-card flat height="100%">
                      <v-card-text>
                        <!--Order Status-->
                        <v-layout row wrap>
                          <v-flex xs12>
                            <v-layout row wrap>
                              <v-flex xs12 class="py-0">
                                <div class="caption">
                                  Status
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <!--Pending Orders-->
                          <div v-if="order.f_status === 999">
                            <v-flex xs12 class="py-0">
                              <div
                                class="subtitle-1 deep-orange--text text--lighten-2 font-weight-bold"
                              >
                                SAVED AS DRAFT - PENDING
                              </div>
                            </v-flex>
                          </div>
                          <!--Submitted Orders-->
                          <div v-else-if="order.f_download === 1">
                            <v-flex xs12 class="py-0">
                              <div
                                class="subtitle-1 grey--text text--darken-3 font-weight-bold"
                              >
                                SUBMITTED
                              </div>
                            </v-flex>
                          </div>
                          <!--Canceled Orders-->
                          <div v-else-if="order.f_status === 1">
                            <v-flex xs12 class="py-0">
                              <div
                                class="subtitle-1 red--text font-weight-bold"
                              >
                                CANCELED
                              </div>
                            </v-flex>
                          </div>
                          <!--Status Timestamps-->
                          <div v-else-if="order.j_statusHistory">
                            <v-flex xs12 class="py-2">
                              <status-line
                                :title="'Submitted'"
                                :data="order.j_statusHistory.submitted"
                              />
                            </v-flex>
                            <v-flex xs12 class="py-2">
                              <status-line
                                :title="'Review'"
                                :data="order.j_statusHistory.review"
                              />
                            </v-flex>
                            <v-flex xs12 class="py-2">
                              <status-line
                                :title="'Pre-Production'"
                                :data="order.j_statusHistory.preProduction"
                              />
                            </v-flex>
                            <v-flex xs12 class="py-2">
                              <status-line
                                :title="'Goods'"
                                :data="order.j_statusHistory.goodsReceived"
                              />
                            </v-flex>
                            <v-flex xs12 class="py-2">
                              <status-line
                                :title="'Production'"
                                :data="order.j_statusHistory.inProduction"
                              />
                            </v-flex>
                            <v-flex xs12 class="py-2">
                              <status-line
                                :title="
                                  order.f_pickUp === 1
                                    ? 'Shipped'
                                    : 'Ready For Pick Up'
                                "
                                :data="order.j_statusHistory.shipped"
                              />
                            </v-flex>
                            <v-flex xs12 class="py-2">
                              <status-line
                                :title="'Accounting'"
                                :data="order.j_statusHistory.invoiced"
                              />
                            </v-flex>
                            <v-flex xs12 class="py-2">
                              <status-line
                                :title="'Closed'"
                                :data="order.j_statusHistory.closed"
                              />
                            </v-flex>
                          </div>
                          <!--No Status Timestamps Exist-->
                          <div v-else>
                            <v-flex xs12 class="py-0">
                              <div
                                class="subtitle-1 grey--text text--darken-3 font-weight-bold"
                              >
                                {{ translateOrderStatus(order.f_status) }}
                              </div>
                            </v-flex>
                          </div>
                        </v-layout>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>

                <!--  Line Items -->
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th
                              class="text-center green lighten-4 grey--text text--darken-1"
                            >
                              Checked In
                            </th>
                            <th
                              class="text-center blue lighten-4 grey--text text--darken-1"
                            >
                              Overage
                            </th>
                            <th
                              class="text-center red lighten-4 grey--text text--darken-1"
                            >
                              Missing
                            </th>
                          </tr>
                        </thead>
                      </template>
                    </v-simple-table>
                    <v-list
                      v-if="order.j_line_items.length > 0"
                      two-line
                      class="pt-0"
                    >
                      <template v-for="(item, i) in order.j_line_items">
                        <v-list-item
                          :key="i"
                          @click="editOrder(order.uuid, i + 1, order.f_status)"
                        >
                          <v-list-item-content>
                            <v-list-item-title
                              class="subtitle-1 grey--text text--darken-3 font-weight-bold"
                            >
                              {{ item.d_productItemNumber }}
                              <span v-if="item.d_color !== 'N/A'">{{
                                item.d_color
                              }}</span>
                              <span v-if="item.d_description"
                                >- {{ item.d_description }}</span
                              >
                            </v-list-item-title>

                            <v-list-item-title
                              v-if="$vuetify.breakpoint.lgAndUp"
                            >
                              <v-simple-table>
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="grey--text text--darken-1">
                                        XS
                                      </th>
                                      <th class="grey--text text--darken-1">
                                        SM
                                      </th>
                                      <th class="grey--text text--darken-1">
                                        MD
                                      </th>
                                      <th class="grey--text text--darken-1">
                                        LG
                                      </th>
                                      <th class="grey--text text--darken-1">
                                        XL
                                      </th>
                                      <th class="grey--text text--darken-1">
                                        XXL
                                      </th>
                                      <th class="grey--text text--darken-1">
                                        XXXL
                                      </th>
                                      <th class="grey--text text--darken-1">
                                        4XL
                                      </th>
                                      <th class="grey--text text--darken-1">
                                        5XL
                                      </th>
                                      <th class="grey--text text--darken-1">
                                        OTHER
                                      </th>
                                      <th class="grey--text text--darken-1">
                                        OSFA
                                      </th>
                                      <th
                                        class="text-right grey--text text--darken-1"
                                      >
                                        TOTAL
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <order-size-block
                                        :value="item.d_sizeXS"
                                        :adjustment="item.d_sizeXSAdjustment"
                                      />
                                      <order-size-block
                                        :value="item.d_sizeSM"
                                        :adjustment="item.d_sizeSMAdjustment"
                                      />
                                      <order-size-block
                                        :value="item.d_sizeMD"
                                        :adjustment="item.d_sizeMDAdjustment"
                                      />
                                      <order-size-block
                                        :value="item.d_sizeLG"
                                        :adjustment="item.d_sizeLGAdjustment"
                                      />
                                      <order-size-block
                                        :value="item.d_sizeXL"
                                        :adjustment="item.d_sizeXLAdjustment"
                                      />
                                      <order-size-block
                                        :value="item.d_sizeXXL"
                                        :adjustment="item.d_sizeXXLAdjustment"
                                      />
                                      <order-size-block
                                        :value="item.d_sizeXXXL"
                                        :adjustment="item.d_sizeXXXLAdjustment"
                                      />
                                      <order-size-block
                                        :value="item.d_size4XL"
                                        :adjustment="item.d_size4XLAdjustment"
                                      />
                                      <order-size-block
                                        :value="item.d_size5XL"
                                        :adjustment="item.d_size5XLAdjustment"
                                      />
                                      <order-size-block
                                        :value="item.d_sizeOther"
                                        :adjustment="item.d_sizeOtherAdjustment"
                                      />
                                      <order-size-block
                                        :value="item.d_sizeOSFA"
                                        :adjustment="item.d_sizeOSFAAdjustment"
                                      />
                                      <td class="text-right">
                                        {{
                                          lineItemTotal(
                                            item.d_sizeXS,
                                            item.d_sizeSM,
                                            item.d_sizeMD,
                                            item.d_sizeLG,
                                            item.d_sizeXL,
                                            item.d_sizeXXL,
                                            item.d_sizeXXXL,
                                            item.d_size4XL,
                                            item.d_size5XL,
                                            item.d_sizeOther,
                                            item.d_sizeOSFA
                                          )
                                        }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-list-item-title>

                            <v-list-item-subtitle
                              v-if="!$vuetify.breakpoint.lgAndUp"
                              class="subtitle-1"
                            >
                              <span
                                v-if="item.d_sizeXS"
                                :class="sizeCheck(item.d_sizeXSAdjustment)"
                                >{{ item.d_sizeXS }} xs
                              </span>
                              <span
                                v-if="item.d_sizeSM"
                                :class="sizeCheck(item.d_sizeSMAdjustment)"
                                >{{ item.d_sizeSM }} sm
                              </span>
                              <span
                                v-if="item.d_sizeMD"
                                :class="sizeCheck(item.d_sizeMDAdjustment)"
                                >{{ item.d_sizeMD }} md
                              </span>
                              <span
                                v-if="item.d_sizeLG"
                                :class="sizeCheck(item.d_sizeLGAdjustment)"
                                >{{ item.d_sizeLG }} lg
                              </span>
                              <span
                                v-if="item.d_sizeXL"
                                :class="sizeCheck(item.d_sizeXLAdjustment)"
                                >{{ item.d_sizeXL }} xl
                              </span>
                              <span
                                v-if="item.d_sizeXXL"
                                :class="sizeCheck(item.d_sizeXXLAdjustment)"
                                >{{ item.d_sizeXXL }} xxl
                              </span>
                              <span
                                v-if="item.d_sizeXXXL"
                                :class="sizeCheck(item.d_sizeXXXLAdjustment)"
                                >{{ item.d_sizeXXXL }} xxxl
                              </span>
                              <span
                                v-if="item.d_size4XL"
                                :class="sizeCheck(item.d_size4XLAdjustment)"
                                >{{ item.d_size4XL }} 4xl
                              </span>
                              <span
                                v-if="item.d_size5XL"
                                :class="sizeCheck(item.d_size5XLAdjustment)"
                                >{{ item.d_size5XL }} 5xl
                              </span>
                              <span
                                v-if="item.d_sizeOther"
                                :class="sizeCheck(item.d_sizeOtherAdjustment)"
                                >{{ item.d_sizeOther }} other
                              </span>
                              <span
                                v-if="item.d_sizeOSFA"
                                :class="sizeCheck(item.d_sizeOSFAAdjustment)"
                                >{{ item.d_sizeOSFA }} osfa
                              </span>
                            </v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-action
                            v-if="!$vuetify.breakpoint.lgAndUp"
                          >
                            <v-list-item-action-text
                              class="subtitle-1 black--text"
                            >
                              {{
                                lineItemTotal(
                                  item.d_sizeXS,
                                  item.d_sizeSM,
                                  item.d_sizeMD,
                                  item.d_sizeLG,
                                  item.d_sizeXL,
                                  item.d_sizeXXL,
                                  item.d_sizeXXXL,
                                  item.d_size4XL,
                                  item.d_size5XL,
                                  item.d_sizeOther,
                                  item.d_sizeOSFA
                                )
                              }}
                            </v-list-item-action-text>
                          </v-list-item-action>
                        </v-list-item>
                        <div v-if="item.j_images">
                          <v-list-item
                            v-for="image in item.j_images"
                            v-if="
                              image.akf_imageLocationID || image.akf_itemService
                            "
                          >
                            <v-list-item-content>
                              <v-list-item-title class="ml-4">
                                &#8226;
                                {{
                                  translateDesignLocation(
                                    image.akf_imageLocationID
                                  )
                                }}
                                {{ translateService(image.akf_itemService) }}
                              </v-list-item-title>
                              <v-list-item-subtitle
                                v-if="image.akf_itemOptions"
                                class="ml-4"
                              >
                                {{ translateOptions(image.akf_itemOptions) }}
                              </v-list-item-subtitle>

                              <v-list-item-subtitle
                                v-if="image.d_designName"
                                class="ml-4"
                              >
                                Design: {{ image.d_designName }}
                              </v-list-item-subtitle>

                              <v-list-item-subtitle
                                v-if="image.personalization"
                                class="ml-4"
                              >
                                Personalization:<br />
                                <span v-if="image.personalization.line1Value"
                                  >{{
                                    image.personalization.line1Value
                                  }}
                                  (Font:{{
                                    image.personalization.line1Font
                                  }}
                                  Size:{{
                                    image.personalization.line1Size
                                  }})</span
                                ><br />
                                <span v-if="image.personalization.line2Value"
                                  >{{
                                    image.personalization.line2Value
                                  }}
                                  (Font:{{
                                    image.personalization.line2Font
                                  }}
                                  Size:{{
                                    image.personalization.line2Size
                                  }})</span
                                ><br />
                                <span v-if="image.personalization.line3Value"
                                  >{{
                                    image.personalization.line3Value
                                  }}
                                  (Font:{{
                                    image.personalization.line3Font
                                  }}
                                  Size:{{
                                    image.personalization.line3Size
                                  }})</span
                                ><br />
                                <span v-if="image.personalization.line4Value"
                                  >{{
                                    image.personalization.line4Value
                                  }}
                                  (Font:{{
                                    image.personalization.line4Font
                                  }}
                                  Size:{{
                                    image.personalization.line4Size
                                  }})</span
                                >
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                        <v-divider />
                      </template>
                    </v-list>

                    <v-list v-else>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            class="red--text subtitle-1 text-xs-center"
                          >
                            No Line Items Exist
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-flex>
                </v-layout>

                <!-- Shipments -->
                <v-layout v-if="order.j_shipments" row wrap>
                  <v-flex xs12>
                    <v-toolbar flat>
                      <v-toolbar-title class="primary--text">
                        Shipments
                      </v-toolbar-title>
                      <v-spacer />
                      <export-excel
                        :section="1"
                        :enabled="
                          order.j_shipments && order.j_shipments.length > 0
                        "
                        :export-title="'Export CSV'"
                      />
                    </v-toolbar>

                    <!-- Shipment List -->
                    <v-list three-line class="pt-0">
                      <template v-for="(item, i) in order.j_shipments">
                        <v-list-item :key="i">
                          <v-list-item-avatar>
                            <v-icon color="primary" large>
                              {{ carrierIcon(item.carrierID) }}
                            </v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item.date
                            }}</v-list-item-title>
                            <v-list-item-title>{{
                              item.address
                            }}</v-list-item-title>
                            <v-list-item-title class="text--primary">
                              {{ item.carrier }} {{ item.method }}
                              {{
                                item.account ? " Acct # " + item.account : ""
                              }}
                            </v-list-item-title>
                            <v-list-item-title class="text--primary">
                              {{ item.tracking }}
                            </v-list-item-title>
                            <v-list-item-title
                              v-if="item.note"
                              class="text--primary"
                            >
                              {{ item.note }}
                            </v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn
                              rounded
                              dark
                              color="green lighten-1"
                              @click="goTracking(item.carrierID, item.tracking)"
                            >
                              <v-icon small left> fal fal fa-truck </v-icon
                              >Track
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider />
                      </template>
                    </v-list>
                  </v-flex>
                </v-layout>

                <!-- Approvals -->
                <v-layout v-if="order.approvals.length > 0" row wrap>
                  <v-flex xs12>
                    <v-toolbar flat>
                      <v-toolbar-title class="primary--text">
                        Design Approvals
                      </v-toolbar-title>
                    </v-toolbar>
                    <!-- Design Approvals -->
                    <v-list three-line class="pt-0">
                      <template v-for="(item, i) in order.approvals">
                        <v-list-item :key="i" @click="goApproval(item.uuid)">
                          <v-list-item-content>
                            <v-list-item-title>
                              Sent - {{ formatTimestamp(item.d_timestampSent) }}
                            </v-list-item-title>
                            <v-list-item-title class="text--primary">
                              {{ item.d_designName }}
                            </v-list-item-title>
                            <v-list-item-title class="text--primary">
                              {{ designLocation(item.design) }}
                            </v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-list-item-action-text
                              :class="
                                'text-xs-right subtitle-1 ' +
                                  [
                                    item.f_status === 2
                                      ? 'green--text lighten-1--text'
                                      : 'deep-orange--text text--lighten-2'
                                  ]
                              "
                            >
                              {{ translateApprovalStatus(item.f_status) }}
                            </v-list-item-action-text>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider />
                      </template>
                    </v-list>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mixin } from "@/mixins/mixin.js";
import StatusLine from "@/components/StatusLine";
import OrderSizeBlock from "@/components/OrderSizeBlock";
import ExportExcel from "@/components/ExportExcel";

export default {
  name: "OrderDetail",
  mixins: [mixin],
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      ordersStore: state => state.ordersStore
    })
  },
  components: {
    StatusLine,
    OrderSizeBlock,
    ExportExcel
  },
  data() {
    return {
      progress: true,
      dialog: false,
      cancelDialog: false,
      search: "",
      order: {
        uuid: "",
        fk_customerID: "",
        fk_shippingCarrierID: "",
        fk_shippingMethodID: "",
        fk_addressID_From: "",
        fk_addressID_To: "",
        fk_userID: "",
        d_dateOrder: "",
        date1: "",
        d_dateDue: "",
        d_orderNumber: "",
        d_jobName: "",
        d_customerPurchaseOrder: "",
        f_pickUp: "",
        f_splitShipment: "",
        f_status: "",
        d_type: "",
        f_priority: "",
        j_line_items: [
          {
            id: "",
            fk_orderID: "",
            fk_customerID: "",
            d_color: "",
            d_description: "",
            d_productItemNumber: "",
            d_sizeXS: "",
            d_sizeSM: "",
            d_sizeMD: "",
            d_sizeLG: "",
            d_sizeXL: "",
            d_sizeXXL: "",
            d_sizeXXXL: "",
            d_sizeOther: "",
            d_sizeOSFA: "",
            j_images: [
              {
                id: "",
                akf_itemService: "",
                akf_imageLocationID: "",
                akf_imageID: "",
                akf_itemOptions: ""
              }
            ]
          }
        ],
        user: {
          name: "",
          email: "",
          d_phone: ""
        }
      }
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      const user = this.$store.getters["usersStore/getAuthUser"];

      if (!user) {
        this.$router.push({ name: "logout" });
      } else {
        const uuid = this.$route.params.orderUUID;

        const order = await this.$store.dispatch("ordersStore/readUUID", uuid);
        this.order = { ...order };
        if (order.d_dateDue) {
          this.order.d_dateDue = this.formatDate(order.d_dateDue);
        }
        this.progress = false;
      }
    },

    translatePriority(priority) {
      if (priority === 1) {
        return "Rush";
      }

      return "No";
    },

    translateType(type) {
      if (type === 1) {
        return "Embroidery";
      }
      if (type === 2) {
        return "Screen Printing";
      }
      if (type === 3) {
        return "PPE Items";
      }
    },

    editOrder(uuid, item, status) {
      if (status === 2 || status === 3 || status === 4 || status === 999) {
        this.$router.push({
          name: "orderEdit",
          params: { orderUUID: uuid, item }
        });
      }
    },

    openCancelDialog() {
      this.cancelDialog = true;
    },

    closeCancelDialog() {
      this.cancelDialog = false;
    },

    async cancelOrder() {
      this.closeCancelDialog();
      this.order.f_status = 1;
      await this.$store.dispatch("ordersStore/cancel", this.order);
    },

    lineItemTotal(xs, sm, md, lg, xl, xxl, xxxl, xxxxl, xxxxxl, osfa, other) {
      const xs2 = xs || 0;
      const sm2 = sm || 0;
      const md2 = md || 0;
      const lg2 = lg || 0;
      const xl2 = xl || 0;
      const xxl2 = xxl || 0;
      const xxxl2 = xxxl || 0;
      const xxxxl2 = xxxxl || 0;
      const xxxxxl2 = xxxxxl || 0;
      const osfa2 = osfa || 0;
      const other2 = other || 0;

      let itemTotal =
        Number(xs2) +
        Number(sm2) +
        Number(md2) +
        Number(lg2) +
        Number(xl2) +
        Number(xxl2) +
        Number(xxxl2) +
        Number(xxxxl2) +
        Number(xxxxxl2) +
        Number(osfa2) +
        Number(other2);
      itemTotal = isNaN(itemTotal) ? 0 : itemTotal;

      return itemTotal;
    },

    goTracking(carrierID, tracking) {
      let url;
      /* UPS */
      if (carrierID === 1) {
        url = "http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=";
      } else if (carrierID === 2) {
        /* FedEx */
        url = "http://www.fedex.com/Tracking?tracknumbers=";
      } else if (carrierID === 3) {
        /* USPS */
        url = "https://tools.usps.com/go/TrackConfirmAction?tLabels=";
      }
      url += tracking;
      window.open(url, "_blank");
    },

    goApproval(uuid) {
      this.$router.push({
        name: "approvalDetail",
        params: { approvalUUID: uuid }
      });
    },

    carrierIcon(carrierID) {
      /* UPS */
      if (carrierID === 1) {
        return "fab fa-ups";
      }
      /* FedEx */
      if (carrierID === 2) {
        return "fab fa-fedex";
      }
      /* USPS */
      if (carrierID === 3) {
        return "fab fa-usps";
      }
      /* Courier */
      if (carrierID === 4) {
        return "fal fa-person-dolly";
      }
    },

    checkComma(value) {
      if (value) {
        return ", ";
      }
      return "";
    },

    designLocation(design) {
      let location = "";
      if (design.f_placementFrontLeftChest === 1) {
        location += `${this.checkComma(location)}Chest Left`;
      }
      if (design.f_placementFrontRightChest === 1) {
        location += `${this.checkComma(location)}Chest Right`;
      }
      if (design.f_placementFrontLeftSleeve === 1) {
        location += `${this.checkComma(location)}Sleeve Left`;
      }
      if (design.f_placementFrontRightSleeve === 1) {
        location += `${this.checkComma(location)}Sleeve Right`;
      }
      if (design.f_placementFrontTopCenter === 1) {
        location += `${this.checkComma(location)}Front Center Top`;
      }
      if (design.f_placementFrontCenterChest === 1) {
        location += `${this.checkComma(location)}Front Full`;
      }
      if (design.f_placementBackTopCenter === 1) {
        location += `${this.checkComma(location)}Back Center Top`;
      }
      if (design.f_placementBackCenter === 1) {
        location += `${this.checkComma(location)}Back Full`;
      }
      if (design.f_placementHatFrontCenter === 1) {
        location += `${this.checkComma(location)}Hat Front`;
      }
      if (design.f_placementHatBackCenter === 1) {
        location += `${this.checkComma(location)}Hat Back`;
      }
      if (design.f_placementHatLeftSide === 1) {
        location += `${this.checkComma(location)}Hat Left Side`;
      }
      if (design.f_placementHatRightSide === 1) {
        location += `${this.checkComma(location)}Hat Right Side`;
      }
      if (design.f_placementOther === 1) {
        location += `${this.checkComma(location)}Other`;
      }
      return location;
    },

    sizeCheck(adjustment) {
      /*Missing*/
      if (adjustment && Number(adjustment) < 0) {
        return "red lighten-4";
      } else if (adjustment && Number(adjustment) > 0) {
        /*Overage*/
        return "blue lighten-4";
      } else if (adjustment && Number(adjustment) === 0) {
        /*All Checked In*/
        return "green lighten-4";
      }
    }
  }
};
</script>

<style scoped>
.lineItemContainer {
  height: 65%;
}
button {
  outline: none;
}
</style>
