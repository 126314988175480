<template>
  <v-container
    fluid
    fill-height
  >
    <v-layout
      align-center
      justify-center
    >
      <v-flex
        xs12
        sm6
      >
        <!--Progress Bar-->
        <v-dialog
          v-model="progress"
          max-width="290"
          persistent
          hide-overlay
        >
          <v-card
            color="primary"
            dark
          >
            <v-card-text class="text-center">
              Loading
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              />
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="termsDialog"
          persistent
          max-width="290"
        >
          <v-card>
            <v-card-title class="headline">
              Terms & Conditions
            </v-card-title>
            <v-card-text>
              The site is in a BETA.  Not all functionality is guaranteeed
              to function at this time.
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="green darken-1"
                text
                @click="termsDialog = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-card
          v-if="!progress"
          class="rounded-card"
        >
          <v-card-title class="secondary white--text headline font-weight-medium">
            MASTER ACCOUNT SET UP
            <v-spacer />
            <v-btn
              text
              icon
              color="white"
              @click="goLogin()"
            >
              <v-icon>fal fa-times</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form
              ref="register"
              v-model="valid"
              lazy-validation
            >
              <v-container
                fluid
                grid-list-sm
              >
                <v-layout
                  row
                  wrap
                >
                  <v-flex>
                    <v-img
                      class="text-center"
                      contain
                      height="80px"
                      :src="require('@/assets/img/logo_small2.png')"
                    />
                  </v-flex>
                </v-layout>

                <v-layout
                  row
                  wrap
                >
                  <v-flex xs12>
                    <div class="title text-center">
                      Welcomes
                    </div>
                  </v-flex>
                </v-layout>

                <v-layout
                  row
                  wrap
                >
                  <v-flex xs12>
                    <div class="headline secondary--text text-center">
                      {{ customersStore.customer.d_companyName }}
                    </div>
                  </v-flex>
                </v-layout>

                <v-layout
                  row
                  wrap
                >
                  <!--User-->
                  <v-flex
                    xs12
                    class="pa-3"
                  >
                    <v-layout
                      row
                      wrap
                    >
                      <v-flex
                        xs12
                        sm6
                      >
                        <v-text-field
                          v-model="data.d_nameFirst"
                          label="First Name"
                          prepend-icon="fal fa-user-circle"
                          required
                          :rules="rules.d_nameFirst"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6
                      >
                        <v-text-field
                          v-model="data.d_nameLast"
                          label="Last Name"
                          prepend-icon="fal fa-user-circle"
                          required
                          :rules="rules.d_nameLast"
                        />
                      </v-flex>
                    </v-layout>

                    <v-layout
                      row
                      wrap
                    >
                      <v-flex xs12>
                        <v-text-field
                          v-model="data.email"
                          label="Email"
                          prepend-icon="fal fa-envelope"
                          required
                          :rules="rules.email"
                        />
                      </v-flex>
                    </v-layout>

                    <v-layout
                      row
                      wrap
                    >
                      <v-flex xs12>
                        <v-text-field
                          v-model="data.password"
                          label="Password"
                          type="password"
                          prepend-icon="fal fa-lock-alt"
                          required
                          :rules="rules.password"
                        />
                      </v-flex>
                    </v-layout>

                    <v-layout
                      row
                      wrap
                    >
                      <v-flex xs12>
                        <v-text-field
                          v-model="passwordConfirm"
                          label="Confirm Password"
                          type="password"
                          prepend-icon="fal fa-lock-alt"
                          required
                          :rules="rules.passwordConfirm"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>

                <v-layout v-if="1==2">
                  <v-flex
                    xs12
                    class="pl-3"
                  >
                    <v-checkbox
                      v-model="agree"
                      color="primary"
                    >
                      <div slot="label">
                        I agree to
                        <a
                          slot="activator"
                          @click="termsDialog = true"
                        >
                          Terms & Conditions
                        </a>
                      </div>
                    </v-checkbox>
                  </v-flex>
                </v-layout>


                <v-btn
                  rounded
                  color="primary"
                  block
                  @click="register()"
                >
                  Submit
                </v-btn>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Register',
  computed: {
    ...mapState({
      customersStore: (state) => state.customersStore,
	    alertsStore: (state) => state.alertsStore,
    }),
  },
  data() {
    return {
      valid: true,
      progress: true,
      termsDialog: '',
      data: {
      	fk_customerID: '',
	      d_nameFirst: '',
        d_nameLast: '',
        email: '',
        d_phone: '',
        password: '',
      },
      passwordConfirm: '',
      agree: '',
      rules: {
        d_nameFirst: [
          (v) => !!v || 'required',
        ],
        d_nameLast: [
          (v) => !!v || 'required',
        ],
        email: [
          (v) => !!v || 'required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        password: [
          (v) => !!v || 'required',
        ],
        passwordConfirm: [
          (v) => !!v || 'required',
          (v) => (!!v && v) === this.data.password
              || 'password doesn\'t match',
        ],
      },
    };
  },

  created() {
    this.initialize();
  },

  methods: {
  	async initialize() {
		  const uuid = this.$route.params.customerUUID;
		  const data = {
		  	uuid,
      };
		  await this.$store.dispatch('customersStore/readInternalUUID', data);
		  const customer = this.$store.getters['customersStore/getCustomer'];
		  this.data.fk_customerID = customer.id;
		  this.progress = false;
		  if (customer.uuid_Internal !== uuid) {
		  	this.goLogin();
      }
    },

	  /* ---------- Sign Up ----------*/
    async register() {
      if (this.$refs.register.validate()) {
      	const user = {
      		email: this.data.email,
        };
      	const userResponse = await axios.post('/api/users/checkemail', user);
        if (userResponse.data) {
	        const data = {
		        color: 'error',
		        text:
              'A user already exists with the provided email address.  Login to the site instead.',
	        };
	        this.$store.commit('alertsStore/setAlert', data);
        } else {
	        const response = await axios.post('/api/users/register', this.data);
	        if (response.data) {
		        const data = {
			        color: 'green lighten-1',
			        text:
                'The primary account has been created successfully.  Login with newly created credentials.',
		        };
		        this.$store.commit('alertsStore/setAlert', data);
		        this.goLogin();
	        } else {
		        const data = {
			        color: 'error',
			        text: 'There was an error creating the master account.  Contact support if you continue to have issues.',
		        };
		        this.$store.commit('alertsStore/setAlert', data);
	        }
        }
      }
    },

    goLogin() {
	      this.$router.push({ name: 'login' });
    },
  },
};
</script>

<style scoped>
  button {
    outline: none;
  }
  button {
    outline: none;
  }
</style>
