import { getHeader } from "@/env";

const state = {
  orders: [],
  orderObject: {},
  filters: {
    id: "",
    customerID: "",
    po: "",
    jobName: "",
    status: "",
    page: "",
    manualFilter: ""
  }
};

const mutations = {
  CLEAR_ORDER_STORE(state) {
    state.orders = [];
    state.orderObject = {};
    state.filters = {};
  },
  FETCH(state, { list }) {
    state.orders = list;
  },
  CREATE(state, { data }) {
    state.orderObject = data;
  },
  READ(state, { data }) {
    state.orderObject = data;
  },
  UPDATE(state, { data }) {
    state.orderObject = data;
  },
  CANCEL(state, { data }) {
    state.orderObject = data;
  },
  DELETE(state, { id }) {
    const idx = state.orders.data.map(item => item.id).indexOf(id);
    state.orders.data.splice(idx, 1);
  },
  STORE_FILTERS(state, { data }) {
    state.filters = data;
  }
};

const actions = {
  clearOrderStore: ({ commit }) => {
    commit("CLEAR_ORDER_STORE");
  },
  async fetch({ commit }) {
    try {
      const response = await axios.get("/api/orders", { headers: getHeader() });
      commit("FETCH", { list: response.data });
    } catch (error) {}
  },
  async fetchByCustomer({ commit }, data) {
    try {
      const response = await axios.post("/api/orders/customer", data, {
        headers: getHeader()
      });
      commit("FETCH", { list: response.data });
    } catch (error) {}
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post("/api/orders/create", data, {
        headers: getHeader()
      });
      commit("CREATE", { data: response.data });
    } catch (error) {}
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/orders/${id}`, {
        headers: getHeader()
      });
      commit("READ", { data: response.data });
    } catch (error) {}
  },
  async readUUID({ commit }, uuid) {
    try {
      const response = await axios.get(`/api/orders/detail/${uuid}`, {
        headers: getHeader()
      });
      commit("READ", { data: response.data });
      return response.data;
    } catch (error) {}
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/orders/update/${data.id}`, data, {
        headers: getHeader()
      });
      commit("UPDATE", { data: response.data });
    } catch (error) {}
  },
  async cancel({ commit }, data) {
    try {
      const response = await axios.put(`/api/orders/cancel/${data.id}`, data, {
        headers: getHeader()
      });
      commit("CANCEL", { data: response.data });
    } catch (error) {}
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post("/api/orders/delete", data, {
        headers: getHeader()
      });
      commit("DELETE", { id: data.id });
    } catch (error) {}
  },
  async query({ commit }, data) {
    try {
      const response = await axios.post("/api/orders/query", data, {
        headers: getHeader()
      });
      commit("FETCH", { list: response.data });
    } catch (error) {}
  },
  async openOrderExport({ commit }, data) {
    try {
      const response = await axios.post("/api/orders/openordersexport", data, {
        headers: getHeader()
      });
      return response.data;
    } catch (error) {}
  },
  storeFilters: ({ commit }, data) => {
    commit("STORE_FILTERS", { data });
  }
};

const getters = {
  getOrder: (state, getters) => state.orderObject,
  getFilters: (state, getters) => state.filters
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
