import { getHeader } from '@/env';

const state = {
  designLocations: [],
  designLocation: {},
};

const mutations = {
  CLEAR_DESIGN_LOCATION_STORE(state) {
    state.designLocations = [];
    state.designLocation = {};
  },
  FETCH(state, { list }) {
    state.designLocations = list;
  },
  CREATE(state, { data }) {
    state.designLocations.push(data);
  },
  READ(state, { data }) {
    state.designLocation = data;
  },
  UPDATE(state, { data }) {
    const idx = state.designLocations.map((item) => item.id).indexOf(data.id);
    state.designLocations.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.designLocations.map((item) => item.id).indexOf(id);
    state.designLocations.splice(idx, 1);
  },
};

const actions = {
  clearDesignLocationStore: ({ commit }) => {
    commit('CLEAR_DESIGN_LOCATION_STORE');
  },
  async fetch({ commit }) {
    try {
      const response = await axios.get('/api/designlocations', { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/designlocations/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/designlocations/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/designlocations/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/designlocations/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async query({ commit }, data) {
    try {
      const response = await axios.post('/api/designlocations/query', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async valueList({ commit }, data) {
    try {
      const response = await axios.post('/api/designlocations/valuelist', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
};

const getters = {
  getLocations: (state, getters) => state.designLocations,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
