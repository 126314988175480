import { getHeader } from '@/env';

const state = {
  products: [],
  product: {},
  productPrice: {},
  productsValueLIst: [],
};

const mutations = {
  CLEAR_PRODUCTS_STORE(state) {
    state.products = [];
    state.productsValueList = [];
  },
  FETCH(state, { list }) {
    state.products = list;
  },
  READ(state, { data }) {
    state.product = data;
  },
  READ_PRICE(state, { data }) {
    state.productPrice = data;
  },
  VALUE_LIST(state, { list }) {
    state.productsValueList = list;
  },
};

const actions = {
  clearProductStore: ({ commit }) => {
    commit('CLEAR_PRODUCTS_STORE');
  },
  async fetch({ commit }) {
    try {
      const response = await axios.get('/api/products', { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, data) {
    try {
      const response = await axios.post('/api/products/read', data, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async valueList({ commit }, data) {
    try {
      const response = await axios.post('/api/products/valuelist', data, { headers: getHeader() });
      commit('VALUE_LIST', { list: response.data });
    } catch (error) {

    }
  },
  async productPrice({ commit }, data) {
    try {
      const response = await axios.post('/api/productpricingitems/read', data, { headers: getHeader() });
      return response.data;
      //commit('READ_PRICE', { data: response.data });
    } catch (error) {

    }
  },
};

const getters = {
  getProducts: (state, getters) => state.products,
  getProduct: (state, getters) => state.product,
  getProductPrice: (state, getters) => state.productPrice,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
