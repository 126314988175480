<template>
  <td :class="sizeCheck()"><span v-if="value !== 0">{{ value }}</span><span v-if="Number(adjustment) < 0 || Number(adjustment) > 0"> (<span v-if="Number(adjustment) > 0">+</span>{{adjustment}})</span></td>
</template>

<script>

export default {
  name: 'OrderSizeBlock',
  props: {
	  value: {
      required: false,
    },
    adjustment: {
      type: String,
      required: false,
    },
  },
  methods: {
    sizeCheck() {
      /*Missing*/
      if(this.adjustment && Number(this.adjustment) < 0){
        return 'red lighten-4';
      }
      /*Overage*/
      else if(this.adjustment && Number(this.adjustment) > 0) {
        return 'blue lighten-4'
      }
      /*All Checked In*/
      else if(this.adjustment && Number(this.adjustment) === 0){
        return 'green lighten-4'
      }
    }
  }
};
</script>

<style scoped>
  
</style>
