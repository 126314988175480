const state = {
  alertMessage: {},
};

const mutations = {
  setAlert(state, data) {
    state.alertMessage = data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
