import { getHeader } from '@/env';

const state = {
  designs: [],
  design: {
    d_jobName: '',
    d_productColor: '',
    d_designName: '',
    d_sizeWidth: '',
    d_sizeHeight: '',
    d_pricing: '',
    j_colors: '',
  },
  filters: {
    customerID: '',
    designName: '',
    jobName: '',
    productColor: '',
    location: '',
    page: '',
  },
};

const mutations = {
  CLEAR_DESIGN_STORE(state) {
    state.designs = [];
    state.design = {};
    state.filters = {};
  },
  FETCH(state, { list }) {
    state.designs = list;
  },
  CREATE(state, { data }) {
    state.designs.push(data);
  },
  READ(state, { data }) {
    state.design = data;
  },
  UPDATE(state, { data }) {
    const idx = state.designs.data.map((item) => item.id).indexOf(data.id);
    state.designs.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.designs.data.map((item) => item.id).indexOf(id);
    state.designs.splice(idx, 1);
  },
  STORE_FILTERS(state, { data }) {
    state.filters = data;
  },
};

const actions = {
  clearDesignStore: ({ commit }) => {
    commit('CLEAR_DESIGN_STORE');
  },
  async fetch({ commit }) {
    try {
      const response = await axios.get('/api/designs', { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async fetchByCustomer({ commit }, data) {
    try {
      const response = await axios.post('/api/designs/customer', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/designs/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/designs/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/designs/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/designs/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async query({ commit }, data) {
    try {
      const response = await axios.post('/api/designs/query', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  storeFilters: ({ commit }, data) => {
    commit('STORE_FILTERS', { data });
  },
};

const getters = {
  getDesign: (state, getters) => state.design,
  getFilters: (state, getters) => state.filters,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
