<template>
  <div>
    <!--Progress Bar-->
    <v-dialog v-model="progress" max-width="290" persistent hide-overlay>
      <v-card color="primary" dark>
        <v-card-text class="text-center">
          Loading
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-container v-if="!progress" fluid fill-height grid-list-lg>
      <v-layout row wrap>
        <v-flex xs12>
          <v-toolbar flat dense color="secondary" dark>
            <v-icon>fal fa-image</v-icon>
            <v-toolbar-title class="pl-2">
              Approval Details
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>

          <v-card flat color="accent">
            <v-card-text class="px-0">
              <v-container fluid grid-list-md class="pt-0 px-0">
                <v-layout row wrap>
                  <v-btn
                    text
                    color="primary"
                    @click="back(approvalsStore.approval.order.uuid)"
                  >
                    BACK TO ORDER
                  </v-btn>
                </v-layout>

                <v-layout row wrap>
                  <!--Details-->
                  <v-flex sx12 md2>
                    <v-card flat height="100%">
                      <v-card-text>
                        <v-container fluid grid-list-sm>
                          <v-layout row wrap>
                            <v-flex xs12 class="py-0">
                              <v-textarea
                                :value="
                                  approvalsStore.approval.design.d_jobName
                                "
                                label="Job Name"
                                placeholder=" "
                                persistent-placeholder
                                flat
                                readonly
                                loading="false"
                                class="pb-0"
                                no-resize
                                rows="2"
                              />
                            </v-flex>
                            <v-flex xs12 class="py-0">
                              <v-text-field
                                :value="
                                  approvalsStore.approval.design.d_productColor
                                "
                                label="Product Color"
                                placeholder=" "
                                persistent-placeholder
                                flat
                                readonly
                                loading="false"
                                class="pb-0"
                              />
                            </v-flex>
                            <v-flex xs12 class="py-0">
                              <v-text-field
                                :value="
                                  approvalsStore.approval.design.d_designName
                                "
                                label="Design Name"
                                placeholder=" "
                                persistent-placeholder
                                flat
                                readonly
                                loading="false"
                                class="pb-0"
                              />
                            </v-flex>

                            <v-flex xs12 md6 class="py-0">
                              <v-text-field
                                :value="
                                  approvalsStore.approval.design.d_sizeWidth
                                "
                                label="Width"
                                placeholder=" "
                                persistent-placeholder
                                flat
                                readonly
                                loading="false"
                                class="pb-0"
                              />
                            </v-flex>
                            <v-flex xs12 md6 class="py-0">
                              <v-text-field
                                :value="
                                  approvalsStore.approval.design.d_sizeHeight
                                "
                                label="Height"
                                placeholder=" "
                                flat
                                readonly
                                loading="false"
                                class="pb-0"
                              />
                            </v-flex>

                            <v-flex xs12 class="py-0">
                              <v-text-field
                                :value="
                                  approvalsStore.approval.design.d_pricing
                                "
                                label="Pricing Count"
                                placeholder=" "
                                persistent-placeholder
                                flat
                                readonly
                                loading="false"
                                class="pb-0"
                              />
                            </v-flex>

                            <v-flex xs12 class="py-0">
                              <v-textarea
                                :value="
                                  designLocation(approvalsStore.approval.design)
                                "
                                label="Location(s)"
                                placeholder=" "
                                persistent-placeholder
                                flat
                                readonly
                                loading="false"
                                class="pb-0"
                                no-resize
                                rows="2"
                              />
                            </v-flex>

                            <v-flex xs12 class="py-0">
                              <div class="caption">
                                Colors
                              </div>
                              <v-list dense>
                                <template
                                  v-for="(item, i) in approvalsStore.approval
                                    .design.j_colors"
                                >
                                  <v-list-item :key="i" class="px-0">
                                    <v-list-item-content>
                                      <v-list-item-title
                                        class="subtitle-1 text-uppercase"
                                      >
                                        {{ item.d_sort }} - {{ item.d_color }}
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                              </v-list>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-flex>

                  <!--Comments-->
                  <v-flex xs12 md3>
                    <v-card flat height="100%">
                      <v-card-text>
                        <v-container fluid grid-list-sm class="pt-0 px-0">
                          <v-layout row wrap>
                            <v-flex
                              xs12
                              class="py-0"
                              v-if="approvalsStore.approval.f_status !== 2"
                            >
                              <v-textarea
                                v-model="comment"
                                label="Add Comment"
                                placeholder=" "
                                persistent-placeholder
                                flat
                                class="pb-0"
                                rows="3"
                              />
                            </v-flex>

                            <v-flex
                              xs12
                              class="py-4"
                              v-if="approvalsStore.approval.f_status !== 2"
                            >
                              <v-btn
                                rounded
                                block
                                dark
                                color="green lighten-1"
                                @click="approval(2)"
                              >
                                <v-icon left> fal fa-smile </v-icon>Approve
                              </v-btn>
                            </v-flex>

                            <v-flex
                              xs12
                              class="py-4"
                              v-if="approvalsStore.approval.f_status !== 2"
                            >
                              <v-btn
                                rounded
                                block
                                dark
                                color="deep-orange lighten-2"
                                @click="approval(3)"
                              >
                                <v-icon left> fal fa-meh </v-icon>Request
                                Changes
                              </v-btn>
                            </v-flex>

                            <v-flex
                              xs12
                              class="py-4"
                              v-if="approvalsStore.approval.f_status === 2"
                            >
                              <span
                                class="display-1 green--text lighten-1--text"
                                >APPROVED</span
                              ><br />
                              <span class="subtitle-1 py-0">{{
                                formatTimestamp(
                                  approvalsStore.approval.d_timestampApproved
                                )
                              }}</span>
                            </v-flex>

                            <!--<v-flex xs12 class="py-4">
                              <v-btn
                                rounded
                                block
                                dark
                                color="error lighten-1"
                                @click="reject()"
                              >
                                <v-icon
                                  left
                                >
                                  fal fa-frown
                                </v-icon>Reject
                              </v-btn>
                            </v-flex>-->

                            <v-flex
                              xs12
                              class="ma-0 py-0"
                              v-if="
                                approvalsStore.approval.j_comments.length > 0
                              "
                            >
                              <div class="caption">
                                Previous Comments
                              </div>
                              <v-list dense>
                                <template
                                  v-for="(item, i) in approvalsStore.approval
                                    .j_comments"
                                >
                                  <v-list-item :key="i" class="px-0">
                                    <v-list-item-content>
                                      <v-list-item-title
                                        class="subtitle-2 text-uppercase"
                                      >
                                        {{ item.Date }} - {{ item.EnteredBy }}
                                      </v-list-item-title>
                                      <v-list-item-title
                                        class="py-2 font-italic subtitle-2"
                                      >
                                        {{ item.Comment }}
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-divider />
                                </template>
                              </v-list>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-flex>

                  <!--Design Preview-->
                  <v-flex xs12 md7>
                    <v-card flat height="100%">
                      <v-card-text>
                        <v-container fluid grid-list-sm class="pt-0 px-0">
                          <v-layout row wrap>
                            <v-flex xs12>
                              <div class="caption">
                                Image Preview
                              </div>
                              <v-img
                                :src="imageURL"
                                max-width="800"
                                max-height="800"
                              />
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mixin } from "@/mixins/mixin.js";
import { clientID, clientSecret, getHeader } from "@/env";

export default {
  name: "ApprovalDetail",
  mixins: [mixin],
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      approvalsStore: state => state.approvalsStore,
      alertsStore: state => state.alertsStore
    })
  },
  data() {
    return {
      progress: true,
      dialog: false,
      imageURL: "",
      comment: ""
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      const user = this.$store.getters["usersStore/getAuthUser"];

      if (!user) {
        this.$router.push({ name: "logout" });
      } else {
        const uuid = this.$route.params.approvalUUID;

        await this.$store.dispatch("approvalsStore/readUUID", uuid);
        const approval = this.$store.getters["approvalsStore/getApproval"];

        if (approval.design.d_path) {
          this.imageURL = await this.designURL(approval.design.d_path);
        }

        this.progress = false;
      }
    },

    checkComma(value) {
      if (value) {
        return ", ";
      }
      return "";
    },

    designLocation(design) {
      let location = "";
      if (design.f_placementFrontLeftChest === 1) {
        location += `${this.checkComma(location)}Chest Left`;
      }
      if (design.f_placementFrontRightChest === 1) {
        location += `${this.checkComma(location)}Chest Right`;
      }
      if (design.f_placementFrontLeftSleeve === 1) {
        location += `${this.checkComma(location)}Sleeve Left`;
      }
      if (design.f_placementFrontRightSleeve === 1) {
        location += `${this.checkComma(location)}Sleeve Right`;
      }
      if (design.f_placementFrontTopCenter === 1) {
        location += `${this.checkComma(location)}Front Center Top`;
      }
      if (design.f_placementFrontCenterChest === 1) {
        location += `${this.checkComma(location)}Front Full`;
      }
      if (design.f_placementBackTopCenter === 1) {
        location += `${this.checkComma(location)}Back Center Top`;
      }
      if (design.f_placementBackCenter === 1) {
        location += `${this.checkComma(location)}Back Full`;
      }
      if (design.f_placementHatFrontCenter === 1) {
        location += `${this.checkComma(location)}Hat Front`;
      }
      if (design.f_placementHatBackCenter === 1) {
        location += `${this.checkComma(location)}Hat Back`;
      }
      if (design.f_placementHatLeftSide === 1) {
        location += `${this.checkComma(location)}Hat Left Side`;
      }
      if (design.f_placementHatRightSide === 1) {
        location += `${this.checkComma(location)}Hat Right Side`;
      }
      if (design.f_placementOther === 1) {
        location += `${this.checkComma(location)}Other`;
      }
      return location;
    },

    async designURL(path) {
      const data = {
        file: path
      };
      const response = await axios.post(
        "/api/artwork/presignedurldesign",
        data,
        { headers: getHeader() }
      );
      return response.data;
    },

    async approval(command) {
      /*Changes Requested*/
      if (command === 3 && !this.comment) {
        let data = {
          color: "error",
          text: "Enter a comment before continuing."
        };
        this.$store.commit("alertsStore/setAlert", data);
      } else {
        const user = this.$store.getters["usersStore/getAuthUser"];

        if (this.comment) {
          var today = new Date();
          var dd = today.getDate();
          var mm = today.getMonth() + 1; //January is 0!
          var yyyy = today.getFullYear();
          var hh = today.getHours();
          var min = today.getMinutes();
          var ss = today.getSeconds();

          if (dd < 10) {
            dd = "0" + dd;
          }

          if (mm < 10) {
            mm = "0" + mm;
          }

          today = mm + "/" + dd + "/" + yyyy + " " + hh + ":" + min + ":" + ss;

          let newComment = {
            Date: today,
            EnteredBy: user.name,
            Comment: this.comment
          };

          await this.$store.dispatch("approvalsStore/comment", newComment);
        }

        const approval = this.$store.getters["approvalsStore/getApproval"];

        let data = {
          id: approval.id,
          j_comments: approval.j_comments,
          f_status: command
        };

        await this.$store.dispatch("approvalsStore/update", data);

        let alert = {
          color: "green lighten-1",
          text: "Design approval changes submitted."
        };
        this.$store.commit("alertsStore/setAlert", alert);

        this.back(approval.order.uuid);
      }
    },

    async reject() {
      if (!this.comment) {
        let data = {
          color: "error",
          text: "Enter a comment before continuing."
        };
        this.$store.commit("alertsStore/setAlert", data);
      } else {
      }
    },

    back(uuid) {
      this.$router.push({ name: "orderDetail", params: { orderUUID: uuid } });
    }
  }
};
</script>

<style scoped>
.lineItemContainer {
  height: 65%;
}
button {
  outline: none;
}
</style>
