import Vue from 'vue';
import VueRouter from 'vue-router';

import Login from '@/views/Login.vue';
import Register from '@/views/Register.vue';
import PasswordReset from '@/views/PasswordReset.vue';
import OrderDetail from '@/views/OrderDetail.vue';
import ApprovalDetail from '@/views/ApprovalDetail.vue';
import SignUp from '@/views/SignUp.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
  },
  {
    path: '/signup',
    name: 'signUp',
    component: SignUp,
  },
  {
    path: '/register/:customerUUID',
    name: 'register',
    component: Register,
  },
  {
    path: '/password/reset/:token',
    name: 'passwordreset',
    component: PasswordReset,
  },
  {
    path: '/static',
    name: 'index',
    component: () => import(/* webpackChunkName: "index" */ '@/views/Index.vue')
  },
  {
    path: '/documentation',
    name: 'documentation',
    component: () => import(/* webpackChunkName: "documentation" */ '@/views/Documentation.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "logout" */ '@/views/Logout.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue')
  },
  {
    path: '/services',
    name: 'services',
    component: () => import(/* webpackChunkName: "services" */ '@/views/Services.vue')
  },
  {
    path: '/resources',
    name: 'resources',
    component: () => import(/* webpackChunkName: "resources" */ '@/views/Resources.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile.vue')
  },
  {
    path: '/shipping',
    name: 'shipping',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "shipping" */ '@/views/Shipping.vue')
  },
  {
    path: '/designs',
    name: 'designs',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "designs" */ '@/views/Designs.vue')
  },
  {
    path: '/orders',
    name: 'orders',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "orders" */ '@/views/Orders.vue')
  },
  {
    path: '/orders/detail/:orderUUID',
    name: 'orderDetail',
    meta: { requiresAuth: true },
    component: OrderDetail,
  },
  {
    path: '/orders/new',
    name: 'orderNew',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "orderedit" */ '@/views/OrderEdit.vue')
  },
  {
    path: '/orders/edit/:orderUUID',
    name: 'orderEdit',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "orderedit" */ '@/views/OrderEdit.vue')
  },
  {
    path: '/invoices',
    name: 'invoices',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "invoices" */ '@/views/Invoices.vue')
  },
  {
    path: '/invoices/detail/:invoiceUUID',
    name: 'invoiceDetail',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "invoicedetail" */ '@/views/InvoiceDetail.vue')
  },
  {
    path: '/pricelists',
    name: 'priceLists',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "invoices" */ '@/views/PriceLists.vue')
  },
  {
    path: '/payments',
    name: 'payments',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "payments" */ '@/views/Payments.vue')
  },
  {
    path: '/payments/detail/:paymentUUID',
    name: 'paymentDetail',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "paymentdetail" */ '@/views/PaymentDetail.vue')
  },
  {
    path: '/customers',
    name: 'customers',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "customers" */ '@/views/Customers.vue')
  },
  {
    path: '/users',
    name: 'users',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "users" */ '@/views/Users.vue')
  },
  {
    path: '/users/detail',
    name: 'user',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "user" */ '@/views/User.vue')
  },
  {
    path: '/countries',
    name: 'countries',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "countries" */ '@/views/Countries.vue')
  },
  {
    path: '/states',
    name: 'states',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "states" */ '@/views/States.vue')
  },
  {
    path: '/helptopics',
    name: 'helpTopics',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "helptopics" */ '@/views/HelpTopics.vue')
  },
  {
    path: '/emailsettings',
    name: 'emailSettings',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "emailssettings" */ '@/views/EmailSettings.vue')
  },
  {
    path: '/approvals/detail/:approvalUUID',
    name: 'approvalDetail',
    meta: { requiresAuth: true },
    component: ApprovalDetail,
  },

];

const router = new VueRouter({
  mode: 'history',
  routes,
});

/**
 * Protect routes
 */
router.beforeEach((to, from, next) => {
  let logout = 0;
  /* If going to logout route */
  if (to.name === 'logout') {
    window.localStorage.removeItem('timer');
  }
  /* If login route */
  else if (to.name === 'login' || to.name === 'passwordreset' || to.name === 'register' || to.name === 'documentation' || to.name === 'signUp') {

  }
  /* All other routes */
  else {
    /* Grab previous timer */
    let prevDate = JSON.parse(window.localStorage.getItem('timer'));
    /* If previous date is null */
    if (prevDate === null) {
      /* Set previous date with current timestamp */
      prevDate = new Date();
    }
    /* Turn previous date into date */
    else {
      prevDate = new Date(prevDate);
    }
    /* Grab current Date */
    const currentDate = new Date();
    /* Calculate difference between dates */
    const diff = (currentDate.getTime() - prevDate.getTime()) / 1000;
    /* If difference is greater than 4 hours */
    if (diff > 14400) {
      /* Set logout variable */
      logout = 1;
    }
    /* Update timer */
    else {
      window.localStorage.setItem('timer', JSON.stringify(new Date()));
    }
  }

  /* If logout variable was set */
  if (logout === 1) {
    next({ name: 'logout' });
  }
  /* All routes that require authentication */
  else if (to.meta.requiresAuth) {
    // console.log('auth')
    const authUser = JSON.parse(window.localStorage.getItem('authUser'));
    if (authUser && authUser.access_token) {
      next();
    } else {
      next({ name: 'login' });
    }
  } else {
    // console.log('should stop')
    next();
  }
});

export default router;
