<template>
  <div>
    <v-btn
      rounded
      dark
      :color="enabled === true ? 'green lighten-1' : 'grey lighten-1'"
      @click="exportExcel()"
    >
      <v-icon small left> fas fa-file-csv </v-icon>{{ exportTitle }}
    </v-btn>
  </div>
</template>

<script>
import XLSX from "xlsx";

export default {
  name: "ExportExcel",
  props: {
    section: Number,
    enabled: Boolean,
    exportTitle: String
  },

  methods: {
    async exportExcel() {
      if (this.enabled === true) {
        /* Initialize variables */
        let filename;
        let ws_name;
        let exportData = [];
        let orderData = [];
        let filters;

        /* ----------Order Tracking Numbers----------*/
        if (this.section === 1) {
          filename = "OrderSipments.csv";
          ws_name = "OrderSipments";
          orderData = await this.$store.getters["ordersStore/getOrder"];
          for (let i = 0; i < orderData.j_shipments.length; i++) {
            let newData = {
              Date: orderData.j_shipments[i].date,
              Carrier: orderData.j_shipments[i].carrier,
              Method: orderData.j_shipments[i].method,
              Tracking: orderData.j_shipments[i].tracking,
              Address: orderData.j_shipments[i].address
                ? orderData.j_shipments[i].address
                : ""
            };
            exportData.push(newData);
          }
        }
        // Open Orders Export
        else if (this.section === 2) {
          const user = this.$store.getters["usersStore/getAuthUser"];
          filename = "OpenOrdersReport.csv";
          ws_name = "OpenOrdersReport";
          const data = {
            fk_customerID: user.fk_customerID
          };
          orderData = await this.$store.dispatch(
            "ordersStore/openOrderExport",
            data
          );
          exportData = orderData;
        }

        /* If data exists to export */
        if (exportData) {
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.json_to_sheet(exportData);

          /* Add worksheet to workbook */
          XLSX.utils.book_append_sheet(wb, ws, ws_name);

          /* Create Excel file */
          XLSX.writeFile(wb, filename, { bookType: "csv", cellDates: true });
        }
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
