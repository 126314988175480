import { getHeader } from '@/env';

const state = {
  customers: [],
  customer: {},
};

const mutations = {
  CLEAR_CUSTOMER_STORE(state) {
    state.customers = [];
    state.customer = {};
  },
  FETCH(state, { list }) {
    state.customers = list;
  },
  CREATE(state, { data }) {
    state.customers.push(data);
  },
  READ(state, { data }) {
    state.customer = data;
  },
  UPDATE(state, { data }) {
    const idx = state.customers.map((item) => item.id).indexOf(data.id);
    state.customers.splice(idx, 1, data);
  },
  UPDATE_PROFILE(state, { data }) {
    state.customer = data;
  },
  DELETE(state, { id }) {
    const idx = state.customers.map((item) => item.id).indexOf(id);
    state.customers.splice(idx, 1);
  },
};

const actions = {
  clearCustomerStore: ({ commit }) => {
    commit('CLEAR_CUSTOMER_STORE');
  },
  async fetch({ commit }) {
    try {
      const response = await axios.get('/api/customers', { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async fetchAll({ commit }) {
    try {
      const response = await axios.get('/api/customersall', { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/customers/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/customers/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async readInternalUUID({ commit }, data) {
    try {
      const response = await axios.post('/api/customers/internal', data);
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/customers/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async updateAuthorizeProfile({ commit }, data) {
    try {
      const response = await axios.put(`/api/customers/paymentprofile/${data.id}`, data, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/customers/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async query({ commit }, data) {
    try {
      const response = await axios.post('/api/customers/query', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async updateProfile({ commit }, data) {
    try {
      const response = await axios.put(`/api/customers/update/profile/${data.id}`, data, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  readShipping: ({ commit }, id) => new Promise((resolve, reject) => {
    axios.get(`/api/customers/shipping/${id}`, { headers: getHeader() })
      .then((response) => {
        commit('READ', { data: response.data });
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  }),
};

const getters = {
  getCustomer: (state, getters) => state.customer,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
