<template>
  <v-container
    fluid
    fill-height
  >
    <v-layout
      align-center
      justify-center
    >
      <v-flex
        xs12
        sm8
      >
        <v-dialog
          v-model="termsDialog"
          persistent
          max-width="290"
        >
          <v-card>
            <v-card-title class="headline">
              Terms & Conditions
            </v-card-title>
            <v-card-text>
              By registering for an account with iDex International, you
              agree that iDex International can make contact via the methods provided (email,
              phone, etc.).
              iDex International doesn't provide this contact information to any third
              party and is used solely for the purposes of the services iDex
              International provides customers.
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="green darken-1"
                text
                @click="termsDialog = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-card
          class="rounded-card"
        >
          <v-card-title class="secondary white--text headline font-weight-medium">
            REGISTER FOR AN ACCOUNT
            <v-spacer />
            <v-btn
              text
              icon
              color="white"
              @click="goLogin()"
            >
              <v-icon>fal fa-times</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form
              ref="signUp"
              v-model="valid"
              lazy-validation
            >
              <v-container
                fluid
                grid-list-sm
              >
                <v-layout>
                  <v-flex xs12>
                    <div class="subtitle-1 text-center error--text">
                      Only <span class="font-weight-bold">NEW</span> customers of iDex
                      International should complete
                      the below
                      registration form.<br>Existing customers should contact iDex International
                      for account access.
                    </div>
                  </v-flex>
                </v-layout>
                <v-layout
                  row
                  wrap
                >
                  <!--Left Side-->
                  <v-flex
                    xs12
                    sm6
                    class="pa-3"
                  >
                    <v-layout
                      row
                      wrap
                    >
                      <v-flex xs12>
                        <v-text-field
                          v-model="data.d_companyName"
                          label="Company Name"
                          prepend-icon="fal fa-building"
                          required
                          :rules="rules.d_companyName"
                        />
                      </v-flex>
                    </v-layout>

                    <v-layout
                      row
                      wrap
                    >
                      <v-flex xs12>
                        <v-text-field
                          v-model="data.d_street1"
                          label="Address 1"
                          prepend-icon="fal fa-map-marker-alt"
                          required
                          :rules="rules.d_street1"
                        />
                      </v-flex>
                    </v-layout>

                    <v-layout
                      row
                      wrap
                    >
                      <v-flex xs12>
                        <v-text-field
                          v-model="data.d_street2"
                          label="Address 2"
                        />
                      </v-flex>
                    </v-layout>

                    <v-layout
                      row
                      wrap
                    >
                      <v-flex
                        xs12
                        sm7
                      >
                        <v-text-field
                          v-model="data.d_city"
                          label="City"
                          required
                          :rules="rules.d_city"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm2
                      >
                        <v-text-field
                          v-model="data.d_state"
                          label="State"
                          required
                          :rules="rules.d_state"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm3
                      >
                        <v-text-field
                          v-model="data.d_zip"
                          label="Postal Code"
                          required
                          :rules="rules.d_zip"
                        />
                      </v-flex>
                    </v-layout>

                    <v-layout
                      row
                      wrap
                    >
                      <!--<v-flex xs12 sm10>
                        <v-text-field
                          label="County"
                          v-model="data.d_county">
                        </v-text-field>
                      </v-flex>-->
                      <v-flex
                        xs12
                        sm2
                      >
                        <v-text-field
                          v-model="data.d_country"
                          label="Country"
                          required
                          :rules="rules.d_country"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>

                  <!--Ride Side-->
                  <v-flex
                    xs12
                    sm6
                    class="pa-3"
                  >
                    <v-layout
                      row
                      wrap
                    >
                      <v-flex
                        xs12
                        sm6
                      >
                        <v-text-field
                          v-model="data.d_nameFirst"
                          label="First Name"
                          prepend-icon="fal fa-user-circle"
                          required
                          :rules="rules.d_nameFirst"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6
                      >
                        <v-text-field
                          v-model="data.d_nameLast"
                          label="Last Name"
                          prepend-icon="fal fa-user-circle"
                          required
                          :rules="rules.d_nameLast"
                        />
                      </v-flex>
                    </v-layout>

                    <v-layout
                      row
                      wrap
                    >
                      <v-flex xs12>
                        <v-text-field
                          v-model="data.email"
                          label="Email"
                          prepend-icon="fal fa-envelope"
                          required
                          :rules="rules.email"
                        />
                      </v-flex>
                    </v-layout>

                    <v-layout
                      row
                      wrap
                    >
                      <v-flex xs12>
                        <v-text-field
                          v-model="data.d_phone"
                          label="Phone"
                          prepend-icon="fal fa-mobile"
                          required
                          :rules="rules.d_phone"
                        />
                      </v-flex>
                    </v-layout>

                    <v-layout
                      row
                      wrap
                    >
                      <v-flex xs12>
                        <v-text-field
                          v-model="data.password"
                          label="Password"
                          type="password"
                          prepend-icon="fal fa-lock-alt"
                          required
                          :rules="rules.password"
                        />
                      </v-flex>
                    </v-layout>

                    <v-layout
                      row
                      wrap
                    >
                      <v-flex xs12>
                        <v-text-field
                          v-model="passwordConfirm"
                          label="Confirm Password"
                          type="password"
                          prepend-icon="fal fa-lock-alt"
                          required
                          :rules="rules.passwordConfirm"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>

                <v-layout>
                  <v-flex
                    xs12
                    class="pl-3"
                  >
                    <v-checkbox
                      v-model="agree"
                      color="primary"
                    >
                      <div slot="label">
                        I agree to
                        <a
                          slot="activator"
                          @click="termsDialog = true"
                        >
                          Terms & Conditions
                        </a>
                      </div>
                    </v-checkbox>
                  </v-flex>
                </v-layout>


                <v-btn
                  rounded
                  color="primary"
                  block
                  @click="signUp()"
                >
                  Submit
                </v-btn>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SignUp',
  computed: {
    ...mapState({
      usersStore: (state) => state.usersStore,
	    alertsStore: (state) => state.alertsStore,
    }),
  },
  data() {
    return {
      valid: true,
      termsDialog: '',
      data: {
	        d_companyName: '',
	        d_street1: '',
	        d_street2: '',
	        d_city: '',
	        d_state: '',
	        d_zip: '',
	        d_county: '',
	        d_country: '',
	        d_nameFirst: '',
	        d_nameLast: '',
	        email: '',
	        d_phone: '',
	        password: '',
      },
      passwordConfirm: '',
      agree: '',
      rules: {
        d_companyName: [
          (v) => !!v || 'required',
        ],
        d_street1: [
          (v) => !!v || 'required',
        ],
        d_city: [
          (v) => !!v || 'required',
        ],
        d_state: [
          (v) => !!v || 'required',
        ],
        d_zip: [
          (v) => !!v || 'required',
        ],
        d_country: [
          (v) => !!v || 'required',
        ],
        d_nameFirst: [
          (v) => !!v || 'required',
        ],
        d_nameLast: [
          (v) => !!v || 'required',
        ],
        email: [
          (v) => !!v || 'required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        d_phone: [
          (v) => !!v || 'required',
        ],
        password: [
          (v) => !!v || 'required',
        ],
        passwordConfirm: [
          (v) => !!v || 'required',
          (v) => (!!v && v) === this.data.password
              || 'password doesn\'t match',
        ],
      },
    };
  },
  methods: {
    /* ---------- Sign Up ----------*/
    async signUp() {
      if (this.$refs.signUp.validate()) {
	      const response = await axios.post('/api/customers/register', this.data);
	      if (response) {
          let data
          if(response.data === 'alreadyRegistered') {
            data = {
              color: 'error',
              text: 'Email address has already been registered with the system.  Login instead.',
            };
          } else {
            data = {
              color: 'green lighten-1',
              text: 'Registration submitted successfully.',
            };
          }

		      this.$store.commit('alertsStore/setAlert', data);
		      this.goLogin();
	      } else {
		      const data = {
			      color: 'error',
			      text: 'There was error with registration.',
		      };
		      this.$store.commit('alertsStore/setAlert', data);
	      }
      }
    },

    goLogin() {
	      this.$router.push({ name: 'login' });
    },
  },
};
</script>

<style scoped>
  button {
    outline: none;
  }
</style>
