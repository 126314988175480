<template>
  <div>
    <v-snackbar
      v-model="errorSnackbar"
      :timeout="4000"
      :top="true"
      :color="snackbarColor"
    >
      {{ loginErrorText }}
    </v-snackbar>

    <v-container
      fluid
      fill-height
    >
      <v-layout
        align-center
        justify-center
      >
        <v-flex
          xs12
          sm8
          md6
          lg4
          xl3
        >
          <v-card
            class="rounded-card pt-1"
          >
            <v-layout
              ma-3
            >
              <v-img
                contain
                max-height="300px"
                :src="require('@/assets/img/logo_login.png')"
              />
            </v-layout>
            <v-spacer />
            <v-card-text>
              <v-form
                ref="forgetPassword"
                v-model="valid"
                lazy-validation
                @submit.prevent="resetPassword()"
              >
                <v-text-field
                  v-model="email"
                  prepend-icon="fas fa-user-circle"
                  label="Email Address"
                  type="text"
                  required
                  :rules="rules.email"
                />
                <v-text-field
                  v-model="password"
                  prepend-icon="fas fa-lock"
                  label="Password"
                  type="password"
                  required
                  :rules="rules.password"
                />
                <v-text-field
                  v-model="passwordConfirmation"
                  prepend-icon="fas fa-lock"
                  label="Confirm Password"
                  type="password"
                  required
                  :rules="rules.passwordConfirm"
                />
                <v-btn
                  color="primary"
                  block
                  type="submit"
                  depressed
                >
                  Reset Password
                </v-btn>
                <v-container>
                  <v-layout>
                    <v-flex
                      xs12
                      class="pt-6 text-md-center"
                    >
                      <v-btn
                        flat
                        small
                        color="primary"
                        @click="goLogin()"
                      >
                        Return To Login
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Passwordreset',
  data() {
    return {
      email: '',
      password: '',
      passwordConfirmation: '',
      valid: true,
      errorSnackbar: false,
      loginErrorText: '',
      snackbarColor: '',
      rules: {
        emailAddress: [
          (v) => !!v || 'required',
        ],
        password: [
          (v) => !!v || 'required',
        ],
        passwordConfirm: [
          (v) => !!v || 'required',
          (v) => v === this.password || 'Passwords must match',
        ],
      },
    };
  },

  methods: {
    resetPassword() {
      if (this.$refs.forgetPassword.validate()) {
        /* Grab data from form */
        const data = {
          token: this.$route.params.token,
          email: this.email,
          password: this.password,
          password_confirmation: this.passwordConfirmation,
        };

        axios
          .post('/api/password/reset', data)
        // eslint-disable-next-line
						.then((response) => {
            this.snackbarColor = 'green lighten-1';
            this.loginErrorText = 'Password has been reset.  Return to login and use your new password.';
            this.errorSnackbar = true;
            this.email = '';
            this.password = '';
            this.passwordConfirmation = '';
            this.$refs.forgetPassword.resetValidation();
          })
          .catch((error) => {
            this.snackbarColor = 'error';
            this.loginErrorText = 'There was an error updating the password.  If it has been more than 2 hours from receiving the password link, go back to the login page and click the "Forgot Your Password" again.';
            this.errorSnackbar = true;
            /* Show login error */
            // eslint-disable-next-line
						});
	      this.valid = true;
      }
    },

    goLogin() {
	      this.$router.push({ name: 'login' });
    },
  },
};
</script>

<style scoped>

</style>
