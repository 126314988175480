<template>
  <v-snackbar
    v-model="show"
    :timeout="4000"
    :top="true"
    :color="color"
  >
    {{ text }}
  </v-snackbar>
</template>

<script>

export default {
  name: 'Alert',
  data() {
    return {
      show: false,
      color: '',
      text: '',
    };
  },
  created() {
    this.$store.watch((state) => state.alertsStore.alertMessage, () => {
      const msg = this.$store.state.alertsStore.alertMessage;
      if (msg !== '') {
        this.color = this.$store.state.alertsStore.alertMessage.color;
        this.text = this.$store.state.alertsStore.alertMessage.text;
        this.show = true;
      }
    });
  },
};
</script>

<style scoped>

</style>
