import { getHeader } from '@/env';

const state = {
  approvals: [],
  approval: {},
  filters: {
    id: '',
    po: '',
    jobName: '',
    status: '',
    page: '',
    manualFilter: '',
  },
};

const mutations = {
  CLEAR_APPROVAL_STORE(state) {
    state.approvals = [];
    state.approval = {};
    state.filters = {};
  },
  FETCH(state, { list }) {
    state.approvals = list;
  },
  READ(state, { data }) {
    state.approval = data;
  },
  UPDATE(state, { data }) {
    state.approval = data;
  },
  COMMENT(state, { data }) {
    state.approval.j_comments.unshift(data);
  },
  STORE_FILTERS(state, { data }) {
    state.filters = data;
  },
};

const actions = {
  clearApprovalStore: ({ commit }) => {
    commit('CLEAR_APPROVAL_STORE');
  },
  async fetch({ commit }) {
    try {
      const response = await axios.get('/api/approvals', { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/approvals/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async readUUID({ commit }, uuid) {
    try {
      const response = await axios.get(`/api/approvals/detail/${uuid}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/approvals/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async comment({ commit }, data) {
    try {
      commit('COMMENT', { data: data });
    } catch (error) {

    }
  },
  storeFilters: ({ commit }, data) => {
    commit('STORE_FILTERS', { data });
  },
};

const getters = {
  getApproval: (state, getters) => state.approval,
  getFilters: (state, getters) => state.filters,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
