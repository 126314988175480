import { getHeader } from '@/env';

const state = {
  helpTopics: [],
  helpTopic: null,
};

const mutations = {
  FETCH(state, { list }) {
    state.helpTopics = list;
  },
  CREATE(state, { data }) {
    state.helpTopics.push(data);
  },
  READ(state, { data }) {
    state.helpTopic = data;
  },
  UPDATE(state, { data }) {
    const idx = state.helpTopics.map((item) => item.id).indexOf(data.id);
    state.helpTopics.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.helpTopics.map((item) => item.id).indexOf(id);
    state.helpTopics.splice(idx, 1);
  },
};

const actions = {
  async fetch({ commit }) {
    try {
      const response = await axios.get('/api/helptopics', { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/helptopics/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/helptopics/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/helptopics/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/helptopics/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async query({ commit }, data) {
    try {
      const response = await axios.post('/api/helptopics/query', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async help({ commit }, route) {
    try {
      const response = await axios.get(`/api/helptopics/help/${route}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
};

const getters = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
