import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-pro/css/all.css';

import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import axios from 'axios'

import { siteURL } from '@/env';

window.axios = axios;

const opts = {
  icons: {
    iconfont: 'fa',
  },
  theme: {
    themes: {
      light: {
        primary: '#3c566a',
        secondary: '#1779a2',
        accent: '#f4f5f7',
        error: '#EF5350',
      },
    },
  },
};
Vue.use(Vuetify);

Vue.config.productionTip = false;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.baseURL = siteURL;

new Vue({
  router,
  store,
  vuetify: new Vuetify(opts),
  render: (h) => h(App),
}).$mount('#app');
