import { getHeader } from '@/env';

const state = {
  payments: [
    {
      invoice: [
        {
          d_invoiceNumber: '',
          order: {
            d_customerPurchaseOrder: '',
            d_jobName: '',
          },
        },
      ],
    },
  ],
  payment: {},
  filters: {
    id: '',
    customerID: '',
    orderId: '',
    date: '',
    po: '',
    jobName: '',
    status: '',
    page: '',
  },
};

const mutations = {
  CLEAR_PAYMENT_STORE(state) {
    state.payments = [];
    state.payment = {};
    state.filters = {};
  },
  FETCH(state, { list }) {
    state.payments = list;
  },
  CREATE(state, { data }) {
    state.payments.data.unshift(data);
  },
  READ(state, { data }) {
    state.payment = data;
  },
  UPDATE(state, { data }) {
    const idx = state.payments.data.map((item) => item.id).indexOf(data.id);
    state.payments.data.splice(idx, 1, data);
  },
  DELETE(state, { id }) {
    const idx = state.payments.data.map((item) => item.id).indexOf(id);
    state.payments.data.splice(idx, 1);
  },
  STORE_FILTERS(state, { data }) {
    state.filters = data;
  },
};

const actions = {
  clearPaymentStore: ({ commit }) => {
    commit('CLEAR_PAYMENT_STORE');
  },
  async fetch({ commit }) {
    try {
      const response = await axios.get('/api/payments', { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async fetchByCustomer({ commit }, data) {
    try {
      const response = await axios.post('/api/payments/customer', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  async create({ commit }, data) {
    try {
      const response = await axios.post('/api/payments/create', data, { headers: getHeader() });
      commit('CREATE', { data: response.data });
    } catch (error) {

    }
  },
  async read({ commit }, id) {
    try {
      const response = await axios.get(`/api/payments/${id}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async readUUID({ commit }, uuid) {
    try {
      const response = await axios.get(`/api/payments/detail/${uuid}`, { headers: getHeader() });
      commit('READ', { data: response.data });
    } catch (error) {

    }
  },
  async update({ commit }, data) {
    try {
      const response = await axios.put(`/api/payments/update/${data.id}`, data, { headers: getHeader() });
      commit('UPDATE', { data: response.data });
    } catch (error) {

    }
  },
  async delete({ commit }, data) {
    try {
      const response = await axios.post('/api/payments/delete', data, { headers: getHeader() });
      commit('DELETE', { id: data.id });
    } catch (error) {

    }
  },
  async query({ commit }, data) {
    try {
      const response = await axios.post('/api/payments/query', data, { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  },
  storeFilters: ({ commit }, data) => {
    commit('STORE_FILTERS', { data });
  },
};

const getters = {
  getPayment: (state, getters) => state.payment,
  getFilters: (state, getters) => state.filters,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
