import { getHeader } from '@/env';

const state = {
  priceLists: [],
};

const mutations = {
  FETCH(state, { list }) {
    state.priceLists = list;
  },
};

const actions = {
  async fetch({ commit }) {
    try {
      const response = await axios.get('/api/pricelists', { headers: getHeader() });
      commit('FETCH', { list: response.data });
    } catch (error) {

    }
  }
};

const getters = {
  getPriceList: (state, getters) => state.pricelist,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
