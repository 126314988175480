export const mixin = {
  methods: {
    formatMoney(value) {
      if (value) {
        const formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2
        });

        return formatter.format(value);
      }
    },

    scrubMoney(value) {
      if (value) {
        return value.replace(/\D/g, "");
      }
    },

    formatTimestamp(date) {
      if (!date) return null;

      const [newDate, newTime] = date.split(" ");
      const [year, month, day] = newDate.split("-");
      return `${month}/${day}/${year} ${newTime}`;
    },

    formatTimestampAsDate(date) {
      if (!date) return null;

      const [newDate] = date.split(" ");
      const [year, month, day] = newDate.split("-");
      return `${month}/${day}/${year}`;
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },

    formatThousands(value) {
      if (value.toString().length < 3) {
        return value;
      }
      return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, ",");
    },

    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split(/[\\\/|-]/);
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    translateStatus(status) {
      if (status === 1) {
        return "Active";
      }

      return "Inactive";
    },

    translateApprovalStatus(status) {
      if (status === 1) {
        return "Pending";
      }
      if (status === 2) {
        return "Approved";
      }
      if (status === 3) {
        return "Changes Requested";
      }
      if (status === 4) {
        return "Rejected";
      }
      return "Deleted";
    },

    translateOrderStatus(status, download) {
      if (download === 1) {
        return "Submitted";
      }
      if (status === 1) {
        return "Canceled";
      }
      if (status === 2) {
        return "Review";
      }
      if (status === 3) {
        return "Pre-Production";
      }
      if (status === 4) {
        return "Goods";
      }
      if (status === 5) {
        return "Production";
      }
      if (status === 7) {
        return "Shipped";
      }
      if (status === 8) {
        return "Ready For Pick Up";
      }
      if (status === 6) {
        return "Invoiced";
      }
      if (status === 9) {
        return "Closed";
      }
      if (status === 100) {
        return "All Open";
      }
      return "Pending";
    },

    translatePaymentStatus(status) {
      if (status === 1) {
        return "Approved";
      }
      if (status === 2 || status === 3) {
        return "Declined";
      }
      return "Voided";
    },

    translateInvoiceStatus(status) {
      if (status === 0) {
        return "Paid";
      }
      if (status === 1) {
        return "Open";
      }
      return "Canceled";
    },

    translateDesignLocation(value) {
      if (value === 1) {
        return "Chest Left";
      }
      if (value === 2) {
        return "Chest Right";
      }
      if (value === 3) {
        return "Sleeve Left";
      }
      if (value === 4) {
        return "Sleeve Right";
      }
      if (value === 5) {
        return "Front Center Top";
      }
      if (value === 6) {
        return "Front Full";
      }
      if (value === 7) {
        return "Back Center Top";
      }
      if (value === 8) {
        return "Back Full";
      }
      if (value === 9) {
        return "Hat Front";
      }
      if (value === 10) {
        return "Hat Back";
      }
      if (value === 11) {
        return "Hat Left Side";
      }
      if (value === 12) {
        return "Hat Right Side";
      }
      if (value === 13) {
        return "Other";
      }
      if (value === 14) {
        return "Name";
      }
    },

    translateService(value) {
      if (value === 3) {
        return "Embroidery Shirts";
      }
      if (value === 4) {
        return "Screen Print";
      }
      if (value === 5) {
        return "Screen Charge - New";
      }
      if (value === 11) {
        return "Polybag";
      }
      if (value === 13) {
        return "Digitizing";
      }
      if (value === 30) {
        return "Screen Charge - Repeat";
      }
      if (value === 36) {
        return "Bag/Metallic/Towel/Apron - Emb";
      }
      if (value === 43) {
        return "Unbag/Polybag";
      }
      if (value === 57) {
        return "Polybag/Label";
      }
      if (value === 58) {
        return "Relabel";
      }
      if (value === 77) {
        return "Embroidery Hats";
      }
      if (value === 83) {
        return "Metallic Ink";
      }
      if (value === 84) {
        return "Glow/Reflective Ink";
      }
      if (value === 85) {
        return "On/Above Pocket";
      }
      if (value === 87) {
        return "Bag/Apron/Towel";
      }
      if (value === 88) {
        return "Pant Leg";
      }
      if (value === 89) {
        return "Long Sleeve Print";
      }
      if (value === 90) {
        return "Embroidered Names 1 Line";
      }
      if (value === 91) {
        return "Embroidered Names - 2 Lines";
      }
      if (value === 113) {
        return "HEMMED SOLAR GAITER";
      }
      if (value === 114) {
        return "FACEMASK - EAR LOOPS";
      }
      if (value === 115) {
        return "YOUTH HEMMED SOLAR GAITER";
      }
      if (value === 116) {
        return "Tubular Gaiter";
      }
      if (value === 117) {
        return "FACEMASK - PREMIUM FILTER";
      }
      if (value === 118) {
        return "FACEMASK - 2PLY";
      }
      if (value === 119) {
        return "Youth Comfort Face Mask";
      }
      if (value === 120) {
        return "Comfort Face Mask";
      }
      if (value === 121) {
        return "Face Mask 2-Ply Youth";
      }
      if (value === 122) {
        return "Facemask Youth-Adjustable Tie Straps";
      }
      if (value === 123) {
        return "Facemask Youth-EAR LOOPS";
      }
      if (value === 124) {
        return "Facemask-Youth Premium Filter";
      }
      if (value === 125) {
        return "Youth 2Ply Hemmed Solar Gaiter";
      }
      if (value === 126) {
        return "2Ply Hemmed Solar Gaiter";
      }
      if (value === 127) {
        return "Youth Reversible Hemmed Solar Gaiter";
      }
      if (value === 128) {
        return "Reversible Hemmed Solar Gaiter";
      }
      if (value === 130) {
        return "Arm Sleeve";
      }

      if (value === 45) {
        return "Applique/Emb.";
      }
      if (value === 66) {
        return "Bottle Koozies";
      }
      if (value === 67) {
        return "CAN SLEEVES";
      }
      if (value === 70) {
        return "Flip Flops";
      }
      if (value === 16) {
        return "Heat Press";
      }
      if (value === 23) {
        return "Pad Printing";
      }
      if (value === 49) {
        return "Phone Case";
      }
      if (value === 56) {
        return "Stickers";
      }
      if (value === 69) {
        return "Wall Clocks";
      }
      if (value === 81) {
        return "Sewout - Embroidery";
      }
      if (value === 82) {
        return "Production Sample -  Screen Print";
      }
      if (value === 86) {
        return "Tri-blend Goods";
      }
      if (value === 6) {
        return "Tri-blend Goods";
      }
      if (value === 134) {
        return "Apparel Fulfillment";
      }
      if (value === 135) {
        return "Hardgoods Fulfillment";
      }
    },

    translateOptions(array) {
      let i;
      let result = "";
      for (i = 0; i < array.length; i++) {
        result += this.translateService(array[i]);
        if (i < array.length - 1) {
          result += ", ";
        }
      }
      return result;
    },

    avatar(value) {
      return value.charAt(0);
    },

    checkAuthorizeRequest(response) {
      if (response.data.messages.resultCode === "Ok") {
        return 1;
      }
      return response.data.messages.message;
    },

    translateCarrierMethod(value) {
      if (value === 1) {
        return "Europe First International Priority";
      }
      if (value === 2) {
        return "2 Day";
      }
      if (value === 3) {
        return "Express Saver";
      }
      if (value === 4) {
        return "Ground";
      }
      if (value === 5) {
        return "First Overnight";
      }
      if (value === 6) {
        return "Ground Home Delivery";
      }
      if (value === 7) {
        return "International Economy";
      }
      if (value === 8) {
        return "International First";
      }
      if (value === 9) {
        return "International Priority";
      }
      if (value === 10) {
        return "Priority Overnight";
      }
      if (value === 11) {
        return "SmartPost";
      }
      if (value === 12) {
        return "Standard Overnight";
      }
      if (value === 13) {
        return "Freight Economy";
      }
      if (value === 14) {
        return "1 Day Freight";
      }
      if (value === 15) {
        return "2 Day Freight";
      }
      if (value === 16) {
        return "3 Day Freight";
      }
      if (value === 17) {
        return "National Freight";
      }
      if (value === 18) {
        return "International Economy Freight";
      }
      if (value === 19) {
        return "International Priority Freight";
      }
      if (value === 20) {
        return "First-Class Mail";
      }
      if (value === 21) {
        return "Media Mail";
      }
      if (value === 22) {
        return "Parcel Post";
      }
      if (value === 23) {
        return "Priority Mail";
      }
      if (value === 24) {
        return "Express Mail";
      }
      if (value === 25) {
        return "Express Mail International";
      }
      if (value === 26) {
        return "Priority Mail International";
      }
      if (value === 27) {
        return "First Class Mail International";
      }
      if (value === 28) {
        return "Next Day Air";
      }
      if (value === 29) {
        return "2nd Day Air";
      }
      if (value === 30) {
        return "Ground";
      }
      if (value === 31) {
        return "Worldwide Express";
      }
      if (value === 32) {
        return "Worldwide Expedited";
      }
      if (value === 33) {
        return "Worldwide Standard";
      }
      if (value === 34) {
        return "3 Day Select";
      }
      if (value === 35) {
        return "Next Day Air Saver";
      }
      if (value === 36) {
        return "Next Day Air Early";
      }
      if (value === 37) {
        return "Worldwide Express Plus";
      }
      if (value === 38) {
        return "2nd Day Air A.M.";
      }
      if (value === 39) {
        return "Worldwide Saver";
      }
      if (value === 40) {
        return "Mail Innovations Domestic First-Class Mail";
      }
      if (value === 41) {
        return "Mail Innovations Domestic Priority Mail";
      }
      if (value === 42) {
        return "Mail Innovations Expedited (STD)";
      }
      if (value === 43) {
        return "Mail Innovations Intl";
      }
      if (value === 44) {
        return "Mail Innovations International Economy";
      }
      if (value === 45) {
        return "International Ground";
      }
      if (value === 46) {
        return "SurePost";
      }
      if (value === 47) {
        return "Standard Post";
      }
      if (value === 48) {
        return "2 Day AM";
      }
      if (value === 49) {
        return "Freight";
      }
      if (value === 50) {
        return "Freight Priority";
      }
      if (value === 51) {
        return "Mail Innovations Domestic";
      }
      if (value === 52) {
        return "3rd Party";
      }
      if (value === 53) {
        return "3rd Party";
      }
      if (value === 54) {
        return "First Freight";
      }
      if (value === 55) {
        return "Freight";
      }
    }
  }
};
