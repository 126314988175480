<template>
  <div>
    <v-icon
      v-if="data.error"
      color="red"
    >
      fas fa-exclamation-triangle
    </v-icon>
    <v-icon
      v-else-if="data.check"
      color="green lighten-1"
    >
      fas fa-check-circle
    </v-icon>
    <v-icon
      v-else
      color="grey"
    >
      fal fa-check-circle
    </v-icon>
    <span class="pl-2 subtitle-1 grey--text text--darken-3 font-weight-bold"> {{ title }}</span>
    <span
      v-if="data.message"
      :class="data.error ? 'subtitle-1 subtitle-1 red--text' : 'subtitle-1 grey--text text--darken-3'"
    > - {{ data.message }}</span>
    <span
      v-if="data.timestamp"
      class="subtitle-1 grey--text text--darken-3"
    > - {{ data.timestamp }}</span>
  </div>
</template>

<script>
import { mixin } from '@/mixins/mixin.js';

export default {
  name: 'StatusLine',
  mixins: [mixin],
  props: {
	  title: {
      type: String,
      required: false,
    },
    data: {
      type: Object,
      required: false,
    },
  },
};
</script>

<style scoped>
  button {
    outline: none;
  }
</style>
